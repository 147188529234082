import { FileExcelOutlined } from "@ant-design/icons";
import { Button, DatePicker, Divider, Space, Switch } from "antd";
import dayjs from "dayjs";
import * as React from "react";

import { FileModal } from "@/components/FileModal";
import { downloadFile } from "@/functions/download-file";

import WorksheetTable from "./components/WorksheetTable";

export default function ViewWorksheets() {
  const [filterOnlyWithActionableItems, setFilterOnlyWithActionableItems] = React.useState(true);
  const [filterNotProcessed, setFilterNotProcessed] = React.useState(true);
  const [period, setPeriod] = React.useState();

  const handleOnCreateExcelExport = () => {
    if (!period) return;
    const [completedAfter, completedBefore] = period;

    const startDate = dayjs(completedAfter).format("YYYYMMDD");
    const endDate = dayjs(completedBefore).format("YYYYMMDD");

    downloadFile(
      `reports/worksheets-period?start-date=${startDate}&end-date=${endDate}&only-unprocessed=${filterNotProcessed}&only-actionable-items=${filterOnlyWithActionableItems}`
    );
  };

  const handleOnExcelClick = worksheetId => {
    downloadFile("reports/worksheets/" + worksheetId);
  };

  const handleOnFilesClick = files => {
    FileModal.call({ files });
  };

  return (
    <WorksheetTable
      forPeriod={period}
      onExcelClick={handleOnExcelClick}
      onFilesClick={handleOnFilesClick}
      onlyWithActionableItems={filterOnlyWithActionableItems}
      header={
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Space direction="horizontal">
            <DatePicker.RangePicker
              allowClear={filterNotProcessed && filterOnlyWithActionableItems}
              onChange={dates => {
                setPeriod(dates);
              }}
              value={period}
            />
            <Divider type="vertical" />
            <Switch
              onChange={() => {
                const nextValue = !filterOnlyWithActionableItems;

                setFilterOnlyWithActionableItems(nextValue);
                if (!nextValue && !period) setPeriod([dayjs().subtract(1, "week"), dayjs()]);
              }}
              checked={filterOnlyWithActionableItems}
            />
            <span style={{ marginLeft: 10 }}>Alleen met actiebehoevende items</span>
            <Divider type="vertical" />
            <Switch
              onChange={() => {
                const nextValue = !filterNotProcessed;

                setFilterNotProcessed(nextValue);
                if (!nextValue && !period) setPeriod([dayjs().subtract(1, "week"), dayjs()]);
              }}
              checked={filterNotProcessed}
            />
            <span style={{ marginLeft: 10 }}>Alleen onverwerkt</span>
          </Space>
          <Button disabled={!period} type="primary" onClick={handleOnCreateExcelExport} icon={<FileExcelOutlined />}>
            {period ? "Exporteren" : "Export alleen beschikbaar als datumperiode is geselecteerd"}
          </Button>
        </div>
      }
      isProcessed={!filterNotProcessed}
    />
  );
}
