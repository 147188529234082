import { ApolloError, gql, useMutation } from "@apollo/client";
import { Checkbox, DatePicker, Form, notification, Slider } from "antd";
import { Dayjs } from "dayjs";
import { RRule } from "rrule";

import EmployeePicker from "@/components/EmployeePicker";
import { Modal } from "@/components/Modal";
import TimePicker from "@/components/TimePicker";
import mapGraphQLErrorsToNotifications from "@/functions/map-graphql-errors-to-notifications";

interface CreateScheduledBreakRuleModalProps {
  employeeId: string;
  onClose: () => void;
}

const DAYS_OF_WEEK = [
  { label: "Maandag", value: RRule.MO.weekday },
  { label: "Dinsdag", value: RRule.TU.weekday },
  { label: "Woensdag", value: RRule.WE.weekday },
  { label: "Donderdag", value: RRule.TH.weekday },
  { label: "Vrijdag", value: RRule.FR.weekday },
];

export default function CreateScheduledBreakRuleModal({ employeeId, onClose }: CreateScheduledBreakRuleModalProps) {
  const [form] = Form.useForm();
  const [createScheduledBreakRuleAsync, { loading: isUpdateLoading }] = useMutation(CreateScheduledBreakRuleMutation);

  const handleOnSubmit = async (values: Record<string, string | number>) => {
    if ((values.recurrence as unknown as number[]).length < 1) {
      return notification.error({ message: "Selecteer tenminste 1 werkdag" });
    }

    try {
      const byHour = (values.startTime as unknown as Dayjs).hour();
      const byMinute = (values.startTime as unknown as Dayjs).minute();

      await createScheduledBreakRuleAsync({
        variables: {
          validFrom: values.validFrom,
          validUntil: values.validUntil,
          employeeId: employeeId,
          recurrence: new RRule({ freq: RRule.DAILY, byweekday: values.recurrence, byhour: byHour, byminute: byMinute }).toString(),
          duration: "P00Y00M00DT00H" + values["duration"] + "M00S",
        },
      });

      onClose();
    } catch (error) {
      mapGraphQLErrorsToNotifications(error as ApolloError);
    }
  };

  return (
    <Modal confirmLoading={isUpdateLoading} onCancel={onClose} onOk={() => form.submit()} open title="Pauzeschema regel toevoegen">
      <Form form={form} onFinish={handleOnSubmit} layout="vertical" initialValues={{ employeeId, duration: 30 }}>
        <Form.Item name="employeeId" label="Medewerker" required rules={[{ required: true }]}>
          <EmployeePicker disabled />
        </Form.Item>
        <Form.Item label="Starten per" style={{ marginBottom: 0 }}>
          <Form.Item name="validFrom" style={{ display: "inline-block" }} required rules={[{ required: true }]}>
            <DatePicker />
          </Form.Item>
          <span style={{ display: "inline-block", width: "32px", lineHeight: "32px", textAlign: "center" }}>om</span>
          <Form.Item name="startTime" style={{ display: "inline-block" }} required rules={[{ required: true }]}>
            <TimePicker hideDisabledOptions format="HH:mm" />
          </Form.Item>
        </Form.Item>
        <Form.Item name="validUntil" label="Geldig tot">
          <DatePicker />
        </Form.Item>
        <Form.Item name="recurrence" label="Werkdagen" required rules={[{ required: true }]}>
          <Checkbox.Group options={DAYS_OF_WEEK} />
        </Form.Item>
        <Form.Item name="duration" label="Pauze (in minuten)" required rules={[{ required: true }]}>
          <Slider min={0} max={120} />
        </Form.Item>
      </Form>
    </Modal>
  );
}

const CreateScheduledBreakRuleMutation = gql`
  mutation ($employeeId: ID!, $recurrence: String!, $duration: DateInterval!, $validFrom: DateTime!, $validUntil: DateTime) {
    createScheduledBreakRule(
      input: { employeeId: $employeeId, recurrence: $recurrence, duration: $duration, validFrom: $validFrom, validUntil: $validUntil }
    ) {
      scheduledBreakRule {
        id
        employee {
          id
          email
        }
        recurrence
        duration
        validFrom
        validUntil
        plannedUntil
      }
    }
  }
`;
