import { gql, useMutation, useQuery } from "@apollo/client";
import dayjs, { Dayjs } from "dayjs";

import { useAuthentication } from "@/authentication";
import mapGraphQLErrorsToNotifications from "@/functions/map-graphql-errors-to-notifications";

export function useAppointmentReminder(appointmentId: string) {
  const { user } = useAuthentication();

  const { data } = useQuery(AppointmentRemindMeQuery, {
    variables: { appointmentId, employeeId: user.id },
  });

  const [createAppointmentRemindMeAsync] = useMutation(CreateAppointmentRemindMeMutation);
  const [rescheduleAppointmentRemindMeAsync] = useMutation(RescheduleAppointmentRemindMeMutation);
  const [deleteAppointmentRemindMeAsync] = useMutation(DeleteAppointmentRemindMeMutation);

  const remindAt = async (value: Dayjs) => {
    try {
      if (reminder === undefined) {
        await createAppointmentRemindMeAsync({
          refetchQueries: [AppointmentRemindMeQuery],
          variables: {
            input: {
              appointmentId,
              remindAt: value,
            },
          },
        });
      } else {
        await rescheduleAppointmentRemindMeAsync({
          variables: {
            input: {
              appointmentRemindMeId: reminder.id,
              remindAt: value,
            },
          },
        });
      }
    } catch (error) {
      mapGraphQLErrorsToNotifications(error);
    }
  };

  const remove = async () => {
    if (reminder === undefined) return;

    try {
      await deleteAppointmentRemindMeAsync({
        refetchQueries: [AppointmentRemindMeQuery],
        variables: {
          input: {
            appointmentRemindMeId: reminder.id,
          },
        },
      });
    } catch (error) {
      mapGraphQLErrorsToNotifications(error);
    }
  };

  const reminder = data?.appointmentRemindMes?.[0] ?? undefined;
  return [reminder, { remindAt, remove }] as const;
}

const AppointmentRemindMeQuery = gql`
  query ($appointmentId: ID!, $employeeId: ID!) {
    appointmentRemindMes(appointmentId: $appointmentId, employeeId: $employeeId) {
      id
      remindAt
    }
  }
`;

const CreateAppointmentRemindMeMutation = gql`
  mutation ($input: CreateAppointmentRemindMeInput!) {
    createAppointmentRemindMe(input: $input) {
      appointmentRemindMe {
        id
        remindAt
      }
    }
  }
`;

const RescheduleAppointmentRemindMeMutation = gql`
  mutation ($input: RescheduleAppointmentRemindMeInput!) {
    rescheduleAppointmentRemindMe(input: $input) {
      appointmentRemindMe {
        id
        remindAt
      }
    }
  }
`;

const DeleteAppointmentRemindMeMutation = gql`
  mutation ($input: DeleteAppointmentRemindMeInput!) {
    deleteAppointmentRemindMe(input: $input) {
      message
    }
  }
`;
