import { Flex, Radio, RadioChangeEvent, Space, Tag } from "antd";

import { useBulkEditContext } from "./BulkEditContext";
import { IntegratedScheduleEvent } from "./use-appointment-schedule";
import { formatTitle } from "./use-appointment-schedule";

interface AgendaEventProps {
  event: IntegratedScheduleEvent;
}

export function AgendaEvent({ event }: AgendaEventProps) {
  return event.__appointment !== undefined ? <AppointmentAgendaEvent event={event} /> : <ScheduledBreakAgendaEvent />;
}

function ScheduledBreakAgendaEvent() {
  return <span>Pauze</span>;
}

function AppointmentAgendaEvent({ event }: AgendaEventProps) {
  const bulkEditContext = useBulkEditContext();

  const appointment = event.__appointment;
  const appointmentId = event.__appointment.id;

  const handleOnChange = (e: RadioChangeEvent) => {
    bulkEditContext.setInviteType(appointmentId, e.target.value);
  };

  return (
    <Flex align="center" justify="space-between">
      <Space direction="vertical" size="small" style={{ width: "unset", cursor: "pointer" }}>
        <span>{formatTitle(appointment)}</span>
        <i>{appointment.location.name}</i>
      </Space>

      {(appointment.status === "STATUS_SCHEDULED" || appointment.status === "STATUS_CONFIRMED") && appointment.inviteSentOn !== null ? (
        <Tag>Uitnodiging verzonden</Tag>
      ) : (
        bulkEditContext.mode === "inviting" &&
        appointment.status === "STATUS_SCHEDULED" &&
        appointment.inviteSentOn === null && (
          <div onClick={event => event.stopPropagation()}>
            <Radio.Group
              buttonStyle="solid"
              defaultValue="undetermined"
              onChange={handleOnChange}
              value={bulkEditContext.getInviteTypeForAppointment(appointmentId)}
            >
              <Radio.Button value="exact">Exacte tijd</Radio.Button>
              <Radio.Button value="undetermined">X</Radio.Button>
              <Radio.Button value="time-window">Met tijdvak</Radio.Button>
            </Radio.Group>
          </div>
        )
      )}
    </Flex>
  );
}
