import { Card } from "antd";
import * as React from "react";

import { downloadFile } from "@/functions/download-file";

import DownloadButton from "./DownloadButton";

export default function ProductsSpreadsheet() {
  return (
    <Card title="Alle producten in onderhoud">
      <DownloadButton type="primary" onClick={() => downloadFile("reports/serviced-products")}>
        Downloaden
      </DownloadButton>
    </Card>
  );
}
