import { Card } from "antd";
import * as React from "react";

import { downloadFile } from "@/functions/download-file";

import DownloadButton from "./DownloadButton";

export default function AppointmentOverviewSpreadsheet() {
  return (
    <Card title="Afspraken per relatie">
      <DownloadButton onClick={() => downloadFile("reports/appointment-overview")} type="primary">
        Downloaden
      </DownloadButton>
    </Card>
  );
}
