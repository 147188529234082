import { AVAILABLE_FLAGS } from "./flags";

export function mapFlagsToFormValues(flags: string[], prefix = "flags") {
  return Object.fromEntries(
    FLAGS.map(value => {
      return [`${prefix}.${value}`, flags.includes(value) ?? false];
    })
  );
}

const FLAGS = AVAILABLE_FLAGS.map(x => x.key);
