import { MailOutlined } from "@ant-design/icons";
import { ApolloError, gql, useMutation, useQuery } from "@apollo/client";
import { Form, Input, notification, Skeleton, Space, Switch, Typography, UploadFile } from "antd";
import { useState } from "react";
import ContentEditable from "react-contenteditable";

import { Modal } from "@/components/Modal";
import mapGraphQLErrorsToNotifications from "@/functions/map-graphql-errors-to-notifications";

import AttachmentInput, { filterAttachmentIdentifiers } from "./AttachmentInput";

interface PrepareInviteModalProps {
  appointmentId: string;
  onClose: () => void;
}

interface InviteFormProps {
  attachments: UploadFile[] | null;
  displayTimeWindow: boolean;
  subject: string;
  content: string;
}

export default function PrepareInviteModal({ appointmentId, onClose }: PrepareInviteModalProps) {
  const [form] = Form.useForm<InviteFormProps>();
  const [displayTimeWindow, setDisplayTimeWindow] = useState(false);
  const [sendInviteAsync, { loading: isSendingAsync }] = useMutation(SendInviteMutation);

  const { data } = useQuery(PrepareAppointmentInviteQuery, {
    variables: { appointmentId, displayTimeWindow },
    fetchPolicy: "network-only",
    onCompleted: data => {
      form.setFieldsValue({
        subject: data.prepareAppointmentInvite.subject,
        content: data.prepareAppointmentInvite.content,
        attachments: null,
      });
    },
    onError: error => {
      mapGraphQLErrorsToNotifications(error);
    },
  });

  const handleOnSubmit = async ({ attachments, subject, content }: InviteFormProps) => {
    try {
      await sendInviteAsync({
        variables: {
          appointmentId,
          displayTimeWindow,
          subject,
          content,
          attachments: attachments !== null ? filterAttachmentIdentifiers(attachments) : null,
          useDefaultTemplate: false,
        },
      });

      notification.success({ message: "Uitnodiging is verzonden" });

      onClose();
    } catch (error) {
      mapGraphQLErrorsToNotifications(error as ApolloError);
    }
  };

  return (
    <Modal
      centered
      confirmLoading={isSendingAsync}
      onCancel={onClose}
      onOk={() => form.submit()}
      okButtonProps={{ icon: <MailOutlined /> }}
      okText="Versturen"
      open
      styles={{
        body: {
          overflowY: "auto",
          maxHeight: window.innerHeight - 168,
        },
      }}
      title="Uitnodigingsmail aanpassen"
      width={650}
    >
      {undefined === data ? (
        <Skeleton />
      ) : (
        <Form form={form} layout="vertical" onFinish={handleOnSubmit}>
          <Form.Item name="subject" label="Onderwerp">
            <Input />
          </Form.Item>
          <Space direction="vertical" style={{ marginBottom: 24 }}>
            <Space align="center" size="small">
              <Typography.Text>Tijdsvak in uitnodiging</Typography.Text>
              <Switch checked={displayTimeWindow} onChange={value => setDisplayTimeWindow(value)} />
            </Space>
            <Typography.Text type="secondary">
              Het tijdvak wordt opgeslagen bij de afspraak en aan de klant getoond. Verander deze niet handmatig in de tekst!
            </Typography.Text>
          </Space>
          <Form.Item name="content" label="Inhoud" valuePropName="html" getValueFromEvent={event => event.target.value}>
            {/* @ts-expect-error props are passed in via Form.Item */}
            <ContentEditable
              className="ant-input"
              style={{ borderColor: "rgb(217, 217, 217)", borderRadius: 6, borderStyle: "solid", borderWidth: 2 / 3, padding: 0 }}
            />
          </Form.Item>
          <Form.Item name="attachments">
            <AttachmentInput multiple />
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
}

const PrepareAppointmentInviteQuery = gql`
  query ($appointmentId: ID!, $displayTimeWindow: Boolean!) {
    prepareAppointmentInvite(appointmentId: $appointmentId, displayTimeWindow: $displayTimeWindow) {
      subject
      content
      displayTimeWindow
      startTime
      endTime
    }
  }
`;

const SendInviteMutation = gql`
  mutation (
    $appointmentId: ID!
    $useDefaultTemplate: Boolean!
    $displayTimeWindow: Boolean!
    $subject: String!
    $content: String!
    $attachmentIds: [ID!]
  ) {
    sendAppointmentInvite(
      input: {
        appointmentId: $appointmentId
        useDefaultTemplate: $useDefaultTemplate
        displayTimeWindow: $displayTimeWindow
        subject: $subject
        content: $content
        attachmentIds: $attachmentIds
      }
    ) {
      appointment {
        id
        inviteSentOn
        displayTimeWindow
        timeWindowStart
        timeWindowEnd
      }
    }
  }
`;
