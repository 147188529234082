import { QuestionCircleOutlined } from "@ant-design/icons";
import { ApolloError, gql, useMutation } from "@apollo/client";
import { Form, notification } from "antd";

import { Modal } from "@/components/Modal";
import MonthPicker from "@/components/MonthPicker";
import mapGraphQLErrorsToNotifications from "@/functions/map-graphql-errors-to-notifications";

interface CancelVisitModalProps {
  contractId: string;
  onClose: () => void;
  visitId: string;
}

export default function CancelVisitModal({ contractId, onClose, visitId }: CancelVisitModalProps) {
  const [formInstance] = Form.useForm();
  const [cancelVisitAsync, { loading }] = useMutation(CancelVisitMutation);

  const handleOnCancel = async values => {
    try {
      await cancelVisitAsync({ variables: { contractId, visitId, cancelOn: values.cancelOn } });
      onClose();

      notification.success({ message: "Bezoek is stopgezet" });
    } catch (error) {
      mapGraphQLErrorsToNotifications(error as ApolloError);
    }
  };

  return (
    <Modal confirmLoading={loading} onCancel={onClose} onOk={() => formInstance.submit()} okType="danger" title="Bezoek stopzetten" open>
      <p>
        <QuestionCircleOutlined />
        <span style={{ marginInline: 8 }}>Weet u zeker dat u dit terugkerend bezoek wilt stopzetten?</span>
      </p>
      <p>
        Als u dit terugkerend bezoek wilt stopzetten kunt u een einddatum opgeven. Alle afspraken gekoppeld aan dit bezoek na de einddatum
        worden automatisch geannuleerd en verwijderd.
      </p>
      <Form form={formInstance} onFinish={handleOnCancel} layout="vertical">
        <Form.Item label="Bezoek stopzetten per" name="cancelOn" rules={[{ required: true }]}>
          <MonthPicker format="MMMM, YYYY" />
        </Form.Item>
      </Form>
    </Modal>
  );
}

const CancelVisitMutation = gql`
  mutation ($contractId: ID!, $visitId: ID!, $cancelOn: DateTime) {
    cancelVisit(input: { contractId: $contractId, visitId: $visitId, cancelOn: $cancelOn }) {
      contract {
        id
        visits {
          id
          endingOn
        }
      }
    }
  }
`;
