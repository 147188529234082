import { useQuery } from "@apollo/client";
import { Button, Carousel, Descriptions, Skeleton, Space, Table, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import dayjs from "dayjs";

import { Modal } from "@/components/Modal";
import formatDuration from "@/functions/format-duration";
import formatProductDescription from "@/functions/format-product-description";

import ProductQuery from "./ProductQuery";

interface ShowProductModalProps {
  onClose: () => void;
  onEditPress: () => void;
  productId: string;
}

export default function ShowProductModal({ onClose, onEditPress, productId }: ShowProductModalProps) {
  const { data } = useQuery(ProductQuery, { variables: { productId } });
  const product = data?.product;

  return (
    <Modal
      centered
      onCancel={() => onClose()}
      footer={
        <>
          <Button onClick={onEditPress}>Aanpassen</Button>
          <Button onClick={onClose} type="primary">
            Sluiten
          </Button>
        </>
      }
      title="Product bekijken"
      open
      style={{ minWidth: "50%" }}
    >
      {undefined === product ? (
        <Skeleton />
      ) : (
        <>
          <div style={{ display: "flex", flexDirection: "row", columnGap: 24, marginBottom: 16 }}>
            {undefined !== product && product.productType.images.length > 0 ? (
              <div style={{ marginBlock: "auto", width: 250 }}>
                <Carousel autoplay>
                  {product.productType.images.map((image, index) => (
                    <img key={index} src={image} alt={`${product.productType.description} #${index + 1}`} />
                  ))}
                </Carousel>
              </div>
            ) : (
              <div
                style={{
                  alignItems: "center",
                  borderColor: "var(--fbc-gray-20)",
                  borderRadius: 12,
                  borderStyle: "solid",
                  borderWidth: 2,
                  display: "flex",
                  justifyContent: "center",
                  width: 250,
                }}
              >
                Geen afbeelding beschikbaar
              </div>
            )}
            <Space direction="vertical" size={16} style={{ flex: 1 }}>
              <Typography.Title level={3} style={{ marginBottom: 0 }}>
                {formatProductDescription(product)}
              </Typography.Title>
              <Descriptions bordered column={1} layout="horizontal" size="small">
                <Descriptions.Item label="Ophanglocatie">{product.locationDescription ?? "-"}</Descriptions.Item>
                {product.productType.serialCodeExpr !== null && (
                  <Descriptions.Item label="Serienummer">{product.serialCode ?? "-"}</Descriptions.Item>
                )}
                {product.productType.productionBatchExpr !== null && (
                  <Descriptions.Item label="Productiebatch">{product.productionBatch ?? "-"}</Descriptions.Item>
                )}
                <Descriptions.Item label="Beschrijving">{product.optionalDescription ?? "-"}</Descriptions.Item>
                <Descriptions.Item label="Laatst gecontroleerd">{formatDDMMYYYY(product.lastCheckedOn)}</Descriptions.Item>
                {null !== product.productType.majorMaintenanceInterval && (
                  <Descriptions.Item label="Laatste groot onderhoud">
                    {/* eslint-disable-next-line prettier/prettier */}
                    {`${formatDDMMYYYY(product.lastMajorMaintenanceOn)} (elke ${formatDuration(
                      product.productType.majorMaintenanceInterval
                    )})`}
                  </Descriptions.Item>
                )}
                <Descriptions.Item label={product.productType.isExpirationDateRequired ? "Verloopdatum" : "Vervangdatum"}>
                  {formatDDMMYYYY(product.replacementDate)}
                </Descriptions.Item>
                <Descriptions.Item label="Status">{ProductCondition[product.condition as keyof typeof ProductCondition]}</Descriptions.Item>
                <Descriptions.Item label="Laatste opmerking publiek">{product.latestWorksheetItem?.publicComment ?? "-"}</Descriptions.Item>
                <Descriptions.Item label="Laatste opmerking intern">
                  {product.latestWorksheetItem?.internalComment ?? "-"}
                </Descriptions.Item>
              </Descriptions>
            </Space>
          </div>
          {product.parts.length > 0 && (
            <Table
              bordered
              columns={partsTableColumns}
              dataSource={product.parts}
              rowKey="id"
              pagination={false}
              title={() => "Onderdelen"}
              size="small"
            />
          )}
        </>
      )}
    </Modal>
  );
}

enum ProductCondition {
  CONDITION_GOOD = "Goed",
  CONDITION_ACTION_REQUIRED = "Actie vereist",
  CONDITION_UNKNOWN = "Onbekend",
}

function formatDDMMYYYY(input: string | null | undefined) {
  return typeof input === "string" ? dayjs(input).format("DD/MM/YYYY") : "-";
}

const partsTableColumns: ColumnsType<any> = [
  { title: "Naam", key: "productType", render: product => formatProductDescription(product) },
  { title: "Productiebatch", dataIndex: "productionBatch", render: productionBatch => productionBatch ?? "-" },
  { title: "Laatst gecontroleerd", dataIndex: "lastCheckedOn", render: lastCheckedOn => formatDDMMYYYY(lastCheckedOn) },
  { title: "Verloopdatum", dataIndex: "replacementDate", render: replacementDate => formatDDMMYYYY(replacementDate) },
];
