import { FileExcelOutlined, FilePdfOutlined, MailOutlined, SnippetsOutlined } from "@ant-design/icons";
import { gql, useMutation, useQuery } from "@apollo/client";
import { Button, Checkbox, notification, Popover, Space, Table } from "antd";
import dayjs from "dayjs";
import truncate from "lodash/truncate";
import * as React from "react";
import { FormattedMessage } from "react-intl";

import MaybePopover from "@/components/MaybePopover";
import TableFilterDropdown from "@/components/TableFilterDropdown";
import { formatAppointmentType } from "@/functions/appointment-util";
import formatAddress from "@/functions/format-address";
import mapGraphQLErrorsToNotifications from "@/functions/map-graphql-errors-to-notifications";
import simpleTableFilterSorter from "@/functions/simple-table-filter-sorter";

import MarkWorksheetProcessedMutation from "../graphql/MarkWorksheetProcessedMutation";
import SendWorksheetReportToRelationMutation from "../graphql/SendWorksheetReportToRelationMutation";
import GenerateInvoicePopover from "./GenerateInvoicePopover";
import WorksheetTableItem from "./WorksheetTableItem";

function sortSheets(worksheets) {
  return [...worksheets].sort((a, b) => {
    return new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime();
  });
}

function MarkProcessedCheckbox({ onClick, processedOn }) {
  return (
    <Popover disabled={processedOn !== null} content={<FormattedMessage id="worksheet.markWorksheetProcessed" />}>
      <Checkbox checked={processedOn !== null} onClick={onClick} />
    </Popover>
  );
}

function mapUnique(values) {
  return [...new Map(values.map(item => [item.value, item])).values()];
}

function collectLocationsForFilter(locations) {
  return mapUnique(
    locations.map(location => ({
      text: `${location.name} (${formatAddress(location.address)})`,
      value: location.id,
    }))
  ).sort(simpleTableFilterSorter);
}

function collectRelationsForFilter(relations) {
  return mapUnique(
    relations.map(relation => ({
      text: relation.name,
      value: relation.id,
    }))
  ).sort(simpleTableFilterSorter);
}

function collectRelationsAfasCodesForFilter(relations) {
  return mapUnique(
    relations.map(relation => ({
      text: null !== relation.afasCode ? relation.afasCode.toString() : null,
      value: relation.id,
    }))
  ).sort(simpleTableFilterSorter);
}

export default function WorksheetTable({ onExcelClick, onFilesClick, forPeriod, onlyWithActionableItems, header, isProcessed }) {
  const { data: worksheetsData, loading } = useQuery(WorksheetsQuery, {
    variables: {
      isProcessed: false === isProcessed ? false : null,
      completedBefore: forPeriod ? forPeriod[1] : null,
      completedAfter: forPeriod ? forPeriod[0] : null,
      onlyWithActionableItems,
    },
  });

  const [markProcessedMutation] = useMutation(MarkWorksheetProcessedMutation);
  const [sendWorksheetReportToRelationMutation] = useMutation(SendWorksheetReportToRelationMutation);

  const [sendWorksheetReportSet, setSendWorksheetReportSet] = React.useState(new Set());
  const [expandedRowKeys, setExpandedRowKeys] = React.useState([]);

  const handleOnSendReport = async id => {
    try {
      setSendWorksheetReportSet(curr => {
        curr.add(id);
        return new Set(curr);
      });

      await sendWorksheetReportToRelationMutation({ variables: { worksheetId: id } });
      notification.success({ message: "Rapport verzonden" });
    } catch (error) {
      notification.error({ message: error.message });
    } finally {
      setSendWorksheetReportSet(curr => {
        curr.delete(id);
        return new Set(curr);
      });
    }
  };

  const handleOnMarkProcessed = async worksheetId => {
    try {
      await markProcessedMutation({ variables: { worksheetId } });
      notification.success({ message: "Gemarkeerd als verwerkt" });
    } catch (error) {
      mapGraphQLErrorsToNotifications(error);
    }
  };

  const worksheets = worksheetsData?.worksheets ?? [];

  const columns = React.useMemo(() => {
    const locationColumn = worksheets.map(w => w.location);
    const relationColumn = worksheets.map(w => w.relation);

    return [
      {
        title: <FormattedMessage id="relation.afasCode" />,
        dataIndex: ["relation", "afasCode"],
        filterDropdown: props => <TableFilterDropdown {...props} dataSource={collectRelationsAfasCodesForFilter(relationColumn)} />,
        onFilter: (value, record) => record.relation.id === value,
      },
      {
        title: <FormattedMessage id="relation" />,
        dataIndex: ["relation", "name"],
        filterDropdown: props => <TableFilterDropdown {...props} dataSource={collectRelationsForFilter(relationColumn)} />,
        onFilter: (value, record) => record.relation.id === value,
        render: name => truncate(name),
      },
      {
        title: <FormattedMessage id="location" />,
        key: "location",
        filterDropdown: props => <TableFilterDropdown {...props} dataSource={collectLocationsForFilter(locationColumn)} />,
        onFilter: (value, record) => record.location.id === value,
        render: worksheet => (
          <>
            <span style={{ display: "block" }}>{worksheet.location.name}</span>
            <em style={{ display: "block", marginTop: 4 }}>
              {truncate(formatAddress(worksheet.location.address), { length: 75, separator: /,? +/ })}
            </em>
          </>
        ),
      },
      {
        title: <FormattedMessage id="worksheet.createdOn" />,
        dataIndex: "createdOn",
        render: createdOn => dayjs(createdOn).format("LL"),
      },
      {
        title: <FormattedMessage id="appointment.appointmentType" />,
        dataIndex: "appointment",
        render: appointment => (null !== appointment ? formatAppointmentType(appointment.appointmentType) : "-"),
      },
      {
        title: <FormattedMessage id="worksheet.employee" />,
        dataIndex: ["employee", "username"],
        filters: mapUnique(
          worksheets.map(({ employee }) => ({
            text: employee.username,
            value: employee.id,
          }))
        ).sort(simpleTableFilterSorter),
        onFilter: (value, record) => record.employee.id === value,
      },
      {
        title: <FormattedMessage id="worksheet.numberOfItems" />,
        dataIndex: "numberOfItems",
      },
      {
        title: "Meldingen",
        key: "numberOfActionableItems",
        render: worksheet => worksheet.numberOfRelatedIssues + worksheet.numberOfInternalComments,
      },
      {
        title: null,
        key: "actions",
        render: ({ id, relation, files, invoicedOn, reportSentOn, processedOn }) => (
          <Space direction="horizontal">
            <MarkProcessedCheckbox
              processedOn={processedOn}
              onClick={event => {
                event.stopPropagation();
                handleOnMarkProcessed(id);
              }}
            />
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: 4,
                }}
              >
                <Button
                  icon={<FileExcelOutlined />}
                  onClick={event => {
                    event.stopPropagation();
                    onExcelClick(id);
                  }}
                  size="small"
                >
                  Excel
                </Button>
                {files.length > 0 && (
                  <Button
                    icon={<FilePdfOutlined />}
                    onClick={event => {
                      event.stopPropagation();
                      onFilesClick(files);
                    }}
                    size="small"
                  >
                    PDF
                  </Button>
                )}
              </div>
              {files.length > 0 && (
                <MaybePopover if={null !== reportSentOn} content="Let op! Rapport is reeds verzonden. Nogmaals?">
                  <Button
                    icon={<MailOutlined />}
                    type={null === reportSentOn ? "primary" : "default"}
                    loading={sendWorksheetReportSet.has(id)}
                    onClick={event => {
                      event.stopPropagation();
                      handleOnSendReport(id);
                    }}
                    style={{ marginBottom: 4 }}
                    size="small"
                  >
                    Rapport versturen
                  </Button>
                </MaybePopover>
              )}
              {relation.afasCode && (
                <GenerateInvoicePopover disabled={invoicedOn !== null} worksheetId={id}>
                  <Button icon={<SnippetsOutlined />} type="primary" size="small">
                    Baliedirectfactuur
                  </Button>
                </GenerateInvoicePopover>
              )}
            </div>
          </Space>
        ),
      },
    ];
  }, [worksheets]);

  return (
    <Table
      bordered
      columns={columns}
      className="table-clickable-rows"
      dataSource={sortSheets(worksheets)}
      expandable={{
        expandedRowRender: worksheet => <WorksheetTableItem worksheetId={worksheet.id} />,
        expandedRowKeys: expandedRowKeys,
        onExpandedRowsChange: setExpandedRowKeys,
      }}
      onRow={record => ({
        onClick: () => setExpandedRowKeys(curr => [...curr, record.id]),
      })}
      loading={loading}
      rowKey={worksheet => worksheet.id}
      title={() => header}
    />
  );
}

const WorksheetsQuery = gql`
  query WorksheetsQuery($isProcessed: Boolean, $onlyWithActionableItems: Boolean!, $completedBefore: DateTime, $completedAfter: DateTime) {
    worksheets(
      isProcessed: $isProcessed
      onlyWithActionableItems: $onlyWithActionableItems
      completedBefore: $completedBefore
      completedAfter: $completedAfter
    ) {
      id
      appointment {
        id
        appointmentType {
          id
          name
          category {
            id
            name
          }
        }
      }
      employee {
        id
        username
      }
      relation {
        id
        afasCode
        name
      }
      location {
        id
        name
        address {
          street
          postalCode
          city
          country
        }
      }
      files {
        id
        name
      }
      createdOn
      signedBy
      numberOfItems
      numberOfInternalComments
      numberOfRelatedIssues
      processedOn
      invoicedOn
      reportSentOn
    }
  }
`;
