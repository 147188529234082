import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { gql, useQuery } from "@apollo/client";
import { Alert, Button, Card, Descriptions, Space } from "antd";
import dayjs from "dayjs";
import * as React from "react";
import { useParams } from "react-router-dom";

import AddVisitModal from "./components/AddVisitModal";
import CancelVisitModal from "./components/CancelVisitModal";
import ExtendVisitModal from "./components/ExtendVisitModal";
import MutateContractModal from "./components/MutateContractModal";
import TerminateContractModal from "./components/TerminateContractModal";
import UpdateBatchVisitsModal from "./components/UpdateBatchVisitsModal";
import UpdateSingleVisitModal from "./components/UpdateSingleVisitModal";
import VisitsTable from "./components/VisitsTable";
import { AVAILABLE_FLAGS } from "./flags";

export default function ViewContract() {
  const contractId = useParams<Record<string, string>>().contractId;
  if (!contractId) throw new Error("Cannot access page without contractId");

  const { data } = useQuery<ContractQueryData>(ContractQuery, { variables: { contractId } });
  const [visitIds, setVisitIds] = React.useState<string[]>([]);
  const [currentModal, setCurrentModal] = React.useState<string | undefined>();
  const contract = data?.contract ?? undefined;

  return (
    <>
      {currentModal === "add-visit" && (
        <AddVisitModal
          contractId={contractId}
          onClose={() => {
            setVisitIds([]);
            setCurrentModal(undefined);
          }}
        />
      )}
      {currentModal === "update-single-visit" && (
        <UpdateSingleVisitModal
          contractId={contractId}
          onCancelVisit={() => {
            setCurrentModal("cancel-visit");
          }}
          onExtendVisit={() => {
            setCurrentModal("extend-visit");
          }}
          onClose={() => {
            setCurrentModal(undefined);
          }}
          visitId={visitIds[0]}
        />
      )}
      {currentModal === "update-batch-visits" && (
        <UpdateBatchVisitsModal
          contractId={contractId}
          onExtendVisits={() => {
            setCurrentModal("extend-visit");
          }}
          onClose={() => {
            setCurrentModal(undefined);
          }}
          visitIds={visitIds}
        />
      )}
      {currentModal === "cancel-visit" && (
        <CancelVisitModal
          contractId={contractId}
          visitId={visitIds[0]}
          onClose={() => {
            setCurrentModal(undefined);
          }}
        />
      )}
      {currentModal === "extend-visit" && (
        <ExtendVisitModal contractId={contractId} visitIds={visitIds} onClose={() => setCurrentModal(undefined)} />
      )}
      {currentModal === "mutate-contract" && <MutateContractModal contractId={contractId} onClose={() => setCurrentModal(undefined)} />}
      {currentModal === "terminate-contract" && (
        <TerminateContractModal contractId={contractId} onClose={() => setCurrentModal(undefined)} />
      )}
      {contract?.endingOn && (
        <Alert
          type="info"
          showIcon
          message="Contract is stopgezet"
          description={`Dit contract is stopgezet op ${dayjs(contract.endingOn).format("LL")}.`}
        />
      )}
      <Space direction="vertical" size="middle">
        <Card
          actions={
            contract !== undefined
              ? [
                  <Button key="edit" onClick={() => setCurrentModal("mutate-contract")} icon={<EditOutlined />} type="text">
                    Aanpassen
                  </Button>,
                  <Button
                    key="cancel"
                    danger
                    disabled={null !== contract.endingOn}
                    onClick={() => setCurrentModal("terminate-contract")}
                    icon={<DeleteOutlined />}
                    type="text"
                  >
                    Eindigen
                  </Button>,
                ]
              : []
          }
          bordered
          loading={undefined === contract}
          bodyStyle={{ padding: 0 }}
          title="Algemene contractgegevens"
        >
          {undefined !== contract && (
            <Descriptions bordered layout="horizontal">
              <Descriptions.Item label="Relatie">
                {contract.relation.afasCode !== null ? `(${contract.relation.afasCode}) ${contract.relation.name}` : contract.relation.name}
              </Descriptions.Item>
              <Descriptions.Item label="Startdatum">{dayjs(contract.startingOn).format("MMMM, YYYY")}</Descriptions.Item>
              <Descriptions.Item label="Einddatum">
                {contract.endingOn ? dayjs(contract.endingOn).format("MMMM, YYYY") : "-"}
              </Descriptions.Item>
              <Descriptions.Item label="Verantwoordelijke salesmedewerker">
                {contract.relation.responsibleEmployee?.username ?? "-"}
              </Descriptions.Item>

              {AVAILABLE_FLAGS.map(item => (
                <Descriptions.Item key={item.key} label={item.label}>
                  {contract.defaultFlags.includes(item.key) ? "Ja" : "Nee"}
                </Descriptions.Item>
              ))}
            </Descriptions>
          )}
        </Card>
        <Card title="Repeterende bezoeken" bodyStyle={{ padding: 0 }}>
          <VisitsTable
            contractId={contractId}
            onBatchClick={visitIds => {
              setVisitIds(visitIds);
              setCurrentModal("update-batch-visits");
            }}
            onCancelClick={visitId => {
              setVisitIds([visitId]);
              setCurrentModal("cancel-visit");
            }}
            onCreateClick={() => {
              setCurrentModal("add-visit");
            }}
            onClick={visitId => {
              setVisitIds([visitId]);
              setCurrentModal("update-single-visit");
            }}
          />
        </Card>
      </Space>
    </>
  );
}

const ContractQuery = gql`
  query ContractQuery($contractId: ID!) {
    contract(id: $contractId) {
      id
      relation {
        id
        afasCode
        name
        responsibleEmployee {
          id
          username
        }
      }
      startingOn
      endingOn
      defaultFlags
    }
  }
`;

interface ContractQueryData {
  contract: {
    id: string;
    relation: {
      id: string;
      afasCode: number | null;
      name: string;
      responsibleEmployee: {
        id: string;
        username: string;
      };
    };
    startingOn: string;
    endingOn: string | null;
    defaultFlags: string[];
  };
}
