import { Button, Space } from "antd";

import { Toolbar } from "@/components/Toolbar";

import { useBulkEditContext } from "./BulkEditContext";

export function BulkEditToolbar() {
  const bulkEditContext = useBulkEditContext();

  return (
    <Toolbar>
      {bulkEditContext.mode === "inactive" ? (
        <Button onClick={() => bulkEditContext.startInviteMode()}>Selecteren voor uitnodigingen versturen</Button>
      ) : (
        <Space>
          <Button disabled={bulkEditContext.loading} danger onClick={() => bulkEditContext.cancelInviteMode()}>
            Annuleren
          </Button>
          <Button
            disabled={bulkEditContext.numberOfInvites() === 0}
            loading={bulkEditContext.loading}
            onClick={() => bulkEditContext.confirmInviteMode()}
            type="primary"
          >
            {bulkEditContext.numberOfInvites()} uitnodigingen versturen
          </Button>
        </Space>
      )}
    </Toolbar>
  );
}
