import { DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { Button, Form, notification, Skeleton } from "antd";
import dayjs from "dayjs";
import get from "lodash/get";
import omitBy from "lodash/omitBy";
import pickBy from "lodash/pickBy";
import { useMemo, useState } from "react";

import { Modal } from "@/components/Modal";
import mapGraphQLErrorsToNotifications from "@/functions/map-graphql-errors-to-notifications";

import ProductForm from "../RegisterProductModal/ProductForm";
import ProductQuery from "./ProductQuery";
import UpdateProductMutation from "./UpdateProductMutation";

interface EditProductModalProps {
  onCancelEditPress: () => void;
  onClose: () => void;
  productId: string;
}

export default function EditProductModal({ onCancelEditPress, onClose, productId }: EditProductModalProps) {
  const [form] = Form.useForm();

  const { data } = useQuery(ProductQuery, { variables: { productId } });
  const [updateProductAsync, { loading: isUpdating }] = useMutation(UpdateProductMutation);
  const [allowMissingFields, setAllowMissingFields] = useState<boolean[]>([false]);

  const handleOnChangeAllowMissingFields = (index: number, value: boolean) => {
    setAllowMissingFields(current => {
      const nextValue = [...current];
      nextValue[index] = value;

      return nextValue;
    });
  };

  const handleOnSubmit = async (values: Record<string, any>) => {
    const updatedProps = diff(values, initialValues);

    if (undefined === updatedProps) return onClose();
    const nulledValues = Object.entries(updatedProps).filter(([_key, value]) => value === null);

    const _handleOnSubmit = async () => {
      try {
        const { relationId, ...defaultProps } = omitBy(updatedProps, (_value, key) => key.startsWith("optionalProperties."));

        const optionalProperties_ = pickBy(updatedProps, (_value, key) => key.startsWith("optionalProperties."));
        const optionalProperties = Object.entries(optionalProperties_)
          .map(([key, value]) => ({ key: key.substring("optionalProperties.".length), value }))
          .filter(element => element.value !== undefined);

        await updateProductAsync({
          variables: {
            ...defaultProps,
            optionalProperties,
            allowMissingFields: allowMissingFields.some(value => true === value),
            parts: undefined === updatedProps.parts ? undefined : Object.values(updatedProps.parts),
            id: productId,
          },
        });

        notification.success({ message: "Product gewijzigd" });
        onClose();
      } catch (error) {
        mapGraphQLErrorsToNotifications(error as ApolloError);
      }
    };

    if (nulledValues.length === 0) {
      _handleOnSubmit();
    }

    if (nulledValues.length > 0) {
      Modal.warning({
        title: "Let op!",
        icon: <ExclamationCircleOutlined />,
        content: (
          <>
            <p>
              Je hebt 1 of meerdere velden leeggehaald. Het leeghalen van een veld wordt op dit moment nog niet ondersteund. Dit wordt dus
              niet opgeslagen. Toch doorgaan?
            </p>
            <ul>
              {nulledValues.map(([key, _value]) => (
                <li key={key}>{key}</li>
              ))}
            </ul>
          </>
        ),
        okCancel: true,
        onOk: () => _handleOnSubmit(),
      });
    }
  };

  const handleOnDiscardPress = () => {
    form.setFieldValue("condition", "CONDITION_DESTROYED");
    form.submit();
  };

  const initialValues = useMemo(() => {
    if (undefined === data) return;

    return {
      relationId: data.product.location.relationId,
      ...parseInitialValues(data.product),
    };
  }, [data]);

  return (
    <Modal
      bodyStyle={{
        overflowY: "auto",
        maxHeight: window.innerHeight - 168,
      }}
      centered
      onCancel={onClose}
      footer={
        <div style={{ flexDirection: "row", justifyContent: "space-between", display: "flex" }}>
          <Button danger icon={<DeleteOutlined />} onClick={handleOnDiscardPress}>
            Verwijderen
          </Button>
          <div>
            <Button onClick={onCancelEditPress}>Annuleren</Button>
            <Button loading={isUpdating} onClick={() => form.submit()} type="primary">
              Opslaan
            </Button>
          </div>
        </div>
      }
      title="Product aanpassen"
      open
      style={{ minWidth: "35%" }}
    >
      {undefined === data ? (
        <Skeleton />
      ) : (
        <ProductForm
          form={form}
          onChangeAllowMissingFields={handleOnChangeAllowMissingFields}
          onSubmit={handleOnSubmit}
          initialProduct={data.product}
          initialValues={initialValues}
        />
      )}
    </Modal>
  );
}

function parseOptionalProperties(props: Array<{ key: string; value: string }>) {
  return props.reduce((acc, curr) => Object.assign(acc, { ["optionalProperties." + curr.key]: curr.value }), {});
}

function parseInitialValues(p: Record<string, any>, parent?: Record<string, any>): Record<string, any> {
  return {
    id: p.id,
    locationId: undefined !== parent ? parent.location.id : p.location.id,
    productTypeId: p.productType.id,
    brand: p.brand,
    serialCode: p.serialCode,
    productionBatch: p.productionBatch,
    manufacturingDate: parseTodayjsJs(p.manufacturingDate),
    replacementDate: parseTodayjsJs(p.replacementDate),
    optionalDescription: p.optionalDescription,
    position: p.position,
    locationDescription: p.locationDescription,
    installedOn: parseTodayjsJs(p.installedOn),
    lastMajorMaintenanceOn: parseTodayjsJs(p.lastMajorMaintenanceOn),
    lastCheckedOn: parseTodayjsJs(p.lastCheckedOn),
    condition: p.condition,
    parts: Array.isArray(p.parts) ? p.parts.map(part => parseInitialValues(part, p)) : [],
    ...(Array.isArray(p.optionalProperties) ? parseOptionalProperties(p.optionalProperties) : {}),
  };
}

function parseTodayjsJs(value: string | null | undefined) {
  return typeof value === "string" ? dayjs(value) : undefined;
}

function diff<T extends Record<string, unknown>>(currentVal: T, referenceVal: T | undefined): Partial<T> | undefined {
  type Path = (string | number)[];

  function isChanged(at: Path): boolean {
    const current = get(currentVal, at, undefined);
    const reference = get(referenceVal, at, undefined);

    return current !== reference;
  }

  function innerDiff<U extends Record<string, unknown>>(values: U, path: Path = []) {
    const accumulator: Partial<U> = {};

    (Object.keys(values) as (string | number)[]).forEach(field => {
      const element = values[field];

      if (!Array.isArray(element)) {
        if (isChanged([...path, field])) {
          accumulator[field] = values[field];
        }

        return;
      }

      const diffs = element
        .map((innerElement, index) => {
          const innerVal = innerDiff(innerElement, [...path, field, index]);
          if (undefined === innerVal) return undefined;

          const id = innerElement["id"] as string;
          return { id, ...innerVal };
        })
        .filter(v => undefined !== v);

      if (diffs.length > 0) accumulator[field] = diffs;
    });

    return Object.keys(accumulator).length > 0 ? accumulator : undefined;
  }

  return innerDiff(currentVal);
}
