import { gql, useLazyQuery } from "@apollo/client";
import { Select } from "antd";
import { SelectProps } from "antd/lib/select";
import { useEffect, useRef, useState } from "react";

interface EmployeePickerProps {
  allowSelectAll?: boolean;
  groupId?: string;
  onChange?: (value: any) => void;
  skipDisabled?: boolean;
}

export default function EmployeePicker({
  allowSelectAll,
  disabled,
  groupId,
  onChange,
  skipDisabled = true,
  value,
  ...restProps
}: EmployeePickerProps & SelectProps) {
  const [dataSource, setDataSource] = useState<Array<{ id: string; username: string }>>([]);
  const selectRef = useRef<React.ElementRef<typeof Select>>(null);

  const [employeesQueryAsync] = useLazyQuery(EmployeesQuery);
  const [employeeQueryAsync] = useLazyQuery(EmployeeQuery);

  useEffect(() => {
    async function fetchSingleEmployee() {
      const response = await employeeQueryAsync({ variables: { employeeId: value } });
      setDataSource(response.data?.employee ? [response.data.employee] : []);
    }

    async function fetchAllEmployees() {
      const response = await employeesQueryAsync({ variables: { groupId, skipDisabled } });
      setDataSource(sortEmployees(response.data?.employees ?? []));
    }

    if (disabled && null !== value && undefined !== value) fetchSingleEmployee();
    else fetchAllEmployees();
  }, [disabled, value]);

  const handleOnChange = (value: any) => {
    if (value.includes("select-all")) {
      selectRef.current?.blur();
      requestAnimationFrame(() => onChange?.(dataSource.map(v => v.id)));

      return;
    }

    onChange?.(value);
  };

  return (
    <Select
      {...restProps}
      ref={selectRef}
      onChange={handleOnChange}
      disabled={disabled}
      placeholder={restProps.mode !== undefined ? "Kies medewerkers" : "Kies een medewerker"}
      showSearch
      optionFilterProp="children"
      value={value}
    >
      <>
        {allowSelectAll && (
          <Select.Option key="select-all" value="select-all">
            <i>[Allemaal selecteren]</i>
          </Select.Option>
        )}
        {dataSource.map(employee => (
          <Select.Option key={employee.id} value={employee.id}>
            {employee.username}
          </Select.Option>
        ))}
      </>
    </Select>
  );
}

function sortEmployees(dataSource: Array<{ id: string; username: string }>) {
  return [...dataSource].sort((first, second) => first.username.localeCompare(second.username));
}

const EmployeesQuery = gql`
  query ($groupId: String, $skipDisabled: Boolean) {
    employees(group: $groupId, skipDisabled: $skipDisabled) {
      id
      username
    }
  }
`;

const EmployeeQuery = gql`
  query ($employeeId: ID!) {
    employee(id: $employeeId) {
      id
      username
    }
  }
`;
