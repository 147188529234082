import { DeleteOutlined, FileExcelOutlined, PlusOutlined } from "@ant-design/icons";
import { useQuery } from "@apollo/client";
import { Alert, Button, notification, Table } from "antd";
import { SortOrder } from "antd/lib/table/interface";
import dayjs from "dayjs";
import truncate from "lodash/truncate";
import * as React from "react";
import { rrulestr } from "rrule";

import { formatAppointmentType } from "@/functions/appointment-util";
import { downloadFile } from "@/functions/download-file";
import formatAddress from "@/functions/format-address";

import ContractVisitsQuery from "../graphql/ContractVisitsQuery";

interface VisitsTableProps {
  contractId: string;
  onBatchClick: (visitIds: string[]) => void;
  onCancelClick: (visitId: string) => void;
  onCreateClick: () => void;
  onClick: (visitId: string) => void;
}

function collectUniqueLocations(visits: Array<Record<string, any>>) {
  return [...new Map(visits.map(v => [v.location.id, v.location])).values()];
}

function collectUniqueAppointmentTypes(visits: Array<Record<string, any>>) {
  return [...new Map(visits.map(v => [v.appointmentType.id, v.appointmentType])).values()];
}

export default function VisitsTable({ contractId, onBatchClick, onCancelClick, onCreateClick, onClick }: VisitsTableProps) {
  const { data, loading } = useQuery(ContractVisitsQuery, { variables: { contractId } });

  const [selectedVisits, setSelectedVisits] = React.useState<string[]>([]);

  const handleOnExportClick = () => {
    downloadFile("reports/contract-visits/" + contractId);
  };

  const contract = data?.contract ?? undefined;
  const activeVisits = contract?.visits.filter(v => !v.endingOn || dayjs(v.endingOn).isAfter());

  const columns = React.useMemo(() => {
    const locations = undefined !== contract ? collectUniqueLocations(contract.visits) : [];
    const appointmentTypes = undefined !== contract ? collectUniqueAppointmentTypes(contract.visits) : [];

    return [
      {
        title: "Soort afspraak",
        dataIndex: "appointmentType",
        render: appointmentType => formatAppointmentType(appointmentType),
        onFilter: (value, record) => record.appointmentType.id === value,
        filters: appointmentTypes.map(at => ({ text: formatAppointmentType(at), value: at.id })),
        multiple: true,
      },
      {
        title: "Starten per",
        dataIndex: "startingOn",
        render: startingOn => dayjs(startingOn).format("MMMM, YYYY"),
        defaultSortOrder: "descend" as SortOrder,
        sorter: (a, b) => dayjs(a.startingOn).diff(b.startingOn, "days"),
      },
      {
        title: "Stopzetten per",
        dataIndex: "endingOn",
        render: endingOn => (endingOn ? dayjs(endingOn).format("MMMM, YYYY") : ""),
      },
      {
        title: "Locatie",
        dataIndex: "location",
        onFilter: (value, record) => record.location.id === value,
        multiple: true,
        filters: locations.map(l => ({ text: l.name, value: l.id })),
        filterSearch: true,
        render: location => (
          <>
            <span style={{ display: "block" }}>{location.name}</span>
            <em style={{ display: "block", marginTop: 4 }}>
              {truncate(formatAddress(location.address), { length: 75, separator: /,? +/ })}
            </em>
          </>
        ),
      },
      {
        title: "Herhalen",
        dataIndex: "recurrence",
        render: recurrence => rrulestr(recurrence).toText(),
      },
      {
        title: "Afwijkende instellingen",
        dataIndex: "overrideDefaultFlags",
        render: overrideDefaultFlags => (overrideDefaultFlags ? "Ja" : "Nee"),
      },
      {
        key: "actions",
        render: visit => (
          <DeleteOutlined
            onClick={event => {
              event.stopPropagation();

              if (!visit.endingOn) {
                onCancelClick(visit.id);
              }
            }}
            style={{
              opacity: visit.endingOn ? 0.25 : 1,
            }}
          />
        ),
      },
    ];
  }, [contract]);

  return (
    <Table
      bordered
      className="table-clickable-rows"
      columns={columns}
      dataSource={contract?.visits ?? []}
      loading={loading}
      onRow={element => ({ onClick: () => onClick(element.id) })}
      rowKey={visit => visit.id}
      rowSelection={{
        selectedRowKeys: selectedVisits,
        getCheckboxProps: visit => ({
          disabled: visit.endingOn && dayjs(visit.endingOn).isBefore(),
        }),
        onChange: (selectedRowKeys: React.Key[]) => {
          setSelectedVisits(selectedRowKeys as string[]);
        },
      }}
      title={() => {
        return undefined !== contract ? (
          <>
            <Button.Group>
              {(!contract.endingOn || dayjs(contract.endingOn).isAfter()) && (
                <Button type="primary" icon={<PlusOutlined />} onClick={onCreateClick}>
                  Toevoegen
                </Button>
              )}
              <Button icon={<FileExcelOutlined />} onClick={handleOnExportClick}>
                Exporteren
              </Button>
              <Button
                onClick={() => {
                  if (selectedVisits.length < 1)
                    return notification.warning({ message: "Selecteer eerst locatie(s) die aangepast moeten worden" });

                  onBatchClick(selectedVisits);
                }}
                type="default"
              >
                Aanpassen
              </Button>
            </Button.Group>
            {selectedVisits.length > 0 && (
              <Alert
                action={
                  <Button.Group>
                    <Button
                      disabled={selectedVisits.length === activeVisits.length}
                      onClick={() => setSelectedVisits(activeVisits.map(v => v.id))}
                    >
                      Alles selecteren
                    </Button>
                    <Button onClick={() => setSelectedVisits([])}>Alles deselecteren</Button>
                  </Button.Group>
                }
                message={`${selectedVisits.length} bezoeken geselecteerd`}
                type="info"
                style={{ margin: "12px -17px -17px -17px" }}
              />
            )}
          </>
        ) : null;
      }}
    />
  );
}
