import { ApolloError, gql, useMutation, useQuery } from "@apollo/client";
import { Alert, Form, notification, Skeleton, Space } from "antd";

import { Modal } from "@/components/Modal";
import mapGraphQLErrorsToNotifications from "@/functions/map-graphql-errors-to-notifications";

import LocationForm from "./EditLocationModal/LocationForm";

interface MoveLocationModalProps {
  onClose: () => void;
  relationId: string;
  locationId: string;
}

export default function MoveLocationModal({ onClose, locationId, relationId }: MoveLocationModalProps) {
  const [form] = Form.useForm();

  const [moveLocationAsync, { loading: isMoving }] = useMutation(MoveLocationMutation);
  const { data } = useQuery(RelationQuery, { variables: { relationId } });
  const location = data?.relation?.locations.find(location => location.id === locationId);

  const handleOnSubmit = async values => {
    try {
      await moveLocationAsync({
        variables: {
          ...values,
          relationId,
          addressExtraDetails: undefined,
          prevLocationId: locationId,
          address: { ...values.address, extraDetails: values.addressExtraDetails },
        },
      });

      notification.success({ message: "Locatiegegevens gewijzigd" });

      onClose();
    } catch (error) {
      mapGraphQLErrorsToNotifications(error as ApolloError);
    }
  };

  return (
    <Modal
      bodyStyle={{
        overflowY: "auto",
        maxHeight: window.innerHeight - 168,
      }}
      centered
      confirmLoading={isMoving}
      onCancel={onClose}
      onOk={() => form.submit()}
      title="Locatie verhuizen"
      open
      style={{ minWidth: "55%" }}
    >
      {undefined === location ? (
        <Skeleton />
      ) : (
        <>
          <Alert
            showIcon
            type="info"
            message={
              <span>
                Wil je alleen het adres corrigeren? Dat kan via de <kbd>Aanpassen</kbd> knop.
              </span>
            }
          />
          <Space direction="horizontal" size={44} style={{ marginTop: 16 }}>
            <LocationForm disabled showAddressCorrectionHint={false} initialValues={{ ...location }} />
            <LocationForm
              form={form}
              showAddressCorrectionHint={false}
              onFinish={handleOnSubmit}
              initialValues={{
                ...location,
                afasCode: undefined,
                name: undefined,
                addressExtraDetails: undefined,
                address: undefined,
                __typename: undefined,
              }}
            />
          </Space>
        </>
      )}
    </Modal>
  );
}

const RelationQuery = gql`
  query ($relationId: ID!) {
    relation(id: $relationId) {
      id
      name
      place
      afasCode
      locations {
        id
        name
        afasCode
        contactPerson
        phoneNumber
        mobilePhoneNumber
        primaryEmail
        secondaryEmail
        locale
        planningComment
        onSiteComment
        address {
          street
          extraDetails
          postalCode
          city
          country
          coordinates {
            latitude
            longitude
          }
        }
      }
    }
  }
`;

const MoveLocationMutation = gql`
  mutation (
    $relationId: ID!
    $prevLocationId: ID!
    $name: String!
    $afasCode: Int
    $contactPerson: String!
    $primaryEmail: String
    $secondaryEmail: String
    $phoneNumber: String
    $mobilePhoneNumber: String
    $locale: String!
    $planningComment: String
    $onSiteComment: String
    $address: AddressInput!
  ) {
    moveLocation(
      input: {
        relationId: $relationId
        prevLocationId: $prevLocationId
        name: $name
        afasCode: $afasCode
        contactPerson: $contactPerson
        primaryEmail: $primaryEmail
        secondaryEmail: $secondaryEmail
        phoneNumber: $phoneNumber
        mobilePhoneNumber: $mobilePhoneNumber
        locale: $locale
        planningComment: $planningComment
        onSiteComment: $onSiteComment
        address: $address
      }
    ) {
      relation {
        id
        locations {
          id
          name
          afasCode
          contactPerson
          primaryEmail
          secondaryEmail
          phoneNumber
          mobilePhoneNumber
          address {
            street
            postalCode
            city
            country
            coordinates {
              longitude
              latitude
            }
          }
          planningComment
          onSiteComment
          locale
          deletedAt
        }
      }
    }
  }
`;
