import { Card, DatePicker } from "antd";
import dayjs from "dayjs";
import * as React from "react";

import { downloadFile } from "@/functions/download-file";

import DownloadButton from "./DownloadButton";

export default function MissingWorksheetsSpreadsheet() {
  const [{ start, end }, setPeriod] = React.useState<Record<string, dayjs.Dayjs | null | undefined>>({ start: null, end: null });

  const handleOnDownload = () => {
    if (!start || !end) return Promise.resolve();

    return downloadFile(
      `reports/missing-worksheets-period?start-date=${start.format("YYYY-MM-DD")}&end-date=${end.format("YYYY-MM-DD")}`
    );
  };

  return (
    <Card title="Ontbrekende werkbonnen">
      <div style={{ marginBottom: 8 }}>
        <span>Periode: </span>
        <DatePicker.RangePicker
          onChange={period => {
            setPeriod(curr => ({
              start: period?.[0] ?? curr[0],
              end: period?.[1] ?? curr[1],
            }));
          }}
        />
      </div>

      <DownloadButton type="primary" onClick={handleOnDownload} disabled={!start || !end}>
        Downloaden
      </DownloadButton>
    </Card>
  );
}
