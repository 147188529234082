import { Checkbox, Form } from "antd";

import { AVAILABLE_FLAGS } from "../flags";

interface FlagsInputBlockProps {
  prefix: string;
}

export function FlagsInputBlock({ prefix }: FlagsInputBlockProps) {
  return (
    <Form.Item label="Instellingen">
      {AVAILABLE_FLAGS.map(element => (
        <Form.Item key={element.key} name={`${prefix}.${element.key}`} valuePropName="checked" style={{ marginBottom: 0 }}>
          <Checkbox>${element.label}</Checkbox>
        </Form.Item>
      ))}
    </Form.Item>
  );
}
