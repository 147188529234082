import { Modal as AntModal, ModalProps } from "antd";

export function Modal(props: ModalProps) {
  return <AntModal maskClosable={false} {...props} />;
}

Modal.confirm = AntModal.confirm;
Modal.error = AntModal.error;
Modal.info = AntModal.info;
Modal.success = AntModal.success;
Modal.warn = AntModal.warn;
Modal.warning = AntModal.warning;
