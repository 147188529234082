import { useEffect, useState } from "react";

export function useStorageState<T>(localStorageKey: string, serialize?: (input: T) => string, unserialize?: (input: string | null) => T) {
  const [value, setValue] = useState(() => {
    const storeValue = localStorage.getItem(localStorageKey);
    return undefined !== unserialize ? unserialize(storeValue) : (storeValue as unknown as T);
  });

  useEffect(() => {
    localStorage.setItem(localStorageKey, undefined !== serialize ? serialize(value) : String(value));
  }, [localStorageKey, serialize, value]);

  return [value, setValue] as const;
}
