import { HistoryOutlined, LeftOutlined, RedoOutlined, RightOutlined } from "@ant-design/icons";
import { Button, DatePicker, Divider, Flex, Space } from "antd";
import dayjs from "dayjs";
import { Views } from "react-big-calendar";

import EmployeePicker from "@/components/EmployeePicker";
import RelationPicker from "@/components/RelationPicker";
import { Toolbar } from "@/components/Toolbar";

interface ScheduleToolbarProps {
  date: Date;
  employeeId: string | undefined;
  onChangeDate: (value: Date) => void;
  onChangeEmployeeId: (value: string) => void;
  onChangeView: (value: (typeof Views)[keyof typeof Views]) => void;
  onChangeRelationId: (value: string | undefined) => void;
  onReload: () => void;
  relationId: string | undefined;
  reloading: boolean;
  view: (typeof Views)[keyof typeof Views];
}

export default function ScheduleToolbar({
  date,
  employeeId,
  onChangeDate,
  onChangeEmployeeId,
  onChangeView,
  onChangeRelationId,
  onReload,
  relationId,
  reloading,
  view,
}: ScheduleToolbarProps) {
  const handleOnPrevious = () => {
    const prevValue = dayjs(date).subtract(1, "week").toDate();
    onChangeDate(prevValue);
  };

  const handleOnNext = () => {
    const nextValue = dayjs(date).add(1, "week").toDate();
    onChangeDate(nextValue);
  };

  return (
    <Toolbar>
      <Flex align="start" justify="space-between">
        <Space>
          <Button onClick={() => onChangeDate(dayjs().startOf("week").toDate())} icon={<HistoryOutlined />} />
          <Button.Group>
            <Button onClick={() => handleOnPrevious()} icon={<LeftOutlined />} />
            <Button onClick={() => handleOnNext()} icon={<RightOutlined />} />
          </Button.Group>
          <DatePicker.WeekPicker
            onChange={value => {
              if (value === null) return;

              const nextValue = value.startOf("week");
              onChangeDate(nextValue.toDate());
            }}
            value={dayjs(date)}
          />

          <Divider type="vertical" />

          <EmployeePicker
            disabled={relationId !== undefined}
            groupId="GROUP_SERVICE_EMPLOYEES"
            onChange={onChangeEmployeeId}
            value={employeeId}
            style={{ width: "100%" }}
          />
          <span>of</span>
          <RelationPicker
            allowClear
            onClear={() => onChangeRelationId(undefined)}
            onChange={onChangeRelationId}
            placeholder="Zoek relatie"
            value={relationId}
            style={{ width: "100%", minWidth: 150 }}
          />
        </Space>

        <div style={{ display: "flex", columnGap: 8 }}>
          <Button loading={reloading} icon={<RedoOutlined />} onClick={() => onReload()} />

          {relationId === undefined ? (
            <Button.Group>
              <Button disabled={view === Views.WORK_WEEK} onClick={() => onChangeView(Views.WORK_WEEK)}>
                Agenda
              </Button>
              <Button disabled={view === Views.AGENDA} onClick={() => onChangeView(Views.AGENDA)}>
                Lijst
              </Button>
            </Button.Group>
          ) : (
            <Button onClick={() => onChangeRelationId(undefined)} icon={<LeftOutlined />}>
              Terug
            </Button>
          )}
        </div>
      </Flex>
    </Toolbar>
  );
}
