import { DownloadOutlined, FilePdfOutlined } from "@ant-design/icons";
import { Button, Upload } from "antd";
import { useEffect } from "react";
import { createCallable, ReactCall } from "react-call";

import { Modal } from "@/components/Modal";
import { createDownloadLink, downloadFile } from "@/functions/download-file";

export interface MyFile {
  id: number;
  name: string;
}

interface FileModalProps {
  files: MyFile[];
}

function FileModalInner({ call, files }: ReactCall.Props<FileModalProps, void, void>) {
  useEffect(() => {
    if (files.length !== 1) return;

    const fileId = files[0]?.id;
    downloadFile(`file/${fileId}`);
    call.end();
  }, []);

  return (
    <Modal open title="Bestanden downloaden" footer={<Button onClick={() => call.end()}>Sluit</Button>}>
      <Upload
        fileList={files.map(x => ({
          ...x,
          uid: String(x.id),
          url: createDownloadLink(`file/${x.id}`),
        }))}
        iconRender={() => <FilePdfOutlined />}
        showUploadList={{
          showDownloadIcon: true,
          downloadIcon: <DownloadOutlined />,
          showRemoveIcon: false,
        }}
      />
    </Modal>
  );
}

export const FileModal = createCallable(FileModalInner, 500);
