import { gql, useQuery } from "@apollo/client";
import dayjs from "dayjs";
import { useMemo, useState } from "react";

import { formatAppointmentType } from "@/functions/appointment-util";

export function useAppointmentSchedule(startDate: Date, endDate: Date, employeeId?: string, relationId?: string) {
  const [refetching, setRefetching] = useState(false);

  const scheduleQuery = useQuery(ScheduleQuery, {
    variables: {
      employeeId,
      endTime: endDate,
      startTime: startDate,
    },
    skip: employeeId === undefined,
  });

  const searchQuery = useQuery(AppointmentsQuery, {
    variables: { relationId },
    skip: relationId === undefined,
  });

  const handleRefetch = async () => {
    const refetch = relationId !== undefined ? searchQuery.refetch : scheduleQuery.refetch;

    try {
      setRefetching(true);
      await refetch();
    } finally {
      setRefetching(false);
    }
  };

  const events = useMemo(() => {
    return relationId !== undefined
      ? mapAppointmentsToEvents(searchQuery.data?.appointments ?? [])
      : [
          ...mapAppointmentsToEvents(scheduleQuery.data?.appointments ?? []),
          ...mapScheduledBreaksToEvents(scheduleQuery.data?.scheduledBreaks ?? []),
        ];
  }, [searchQuery.data, scheduleQuery.data, relationId, startDate, endDate]);

  return {
    events,
    loading: (relationId !== undefined ? searchQuery.loading : scheduleQuery.loading) || refetching,
    refetch: handleRefetch,
  };
}

export interface IntegratedScheduleEvent {
  allDay: boolean;
  startTime: Date;
  endTime: Date;
  title: string;
  isResizable: boolean;
  isDraggable: boolean;
  __scheduledBreakId: string | undefined;
  __scheduledBreakRuleId: string | undefined;
  __appointmentId: string | undefined;
  __isShowingRouteOnMap: boolean;
  __appointment: object | undefined;
}

function mapScheduledBreaksToEvents(breaks: Record<string, string>[]): IntegratedScheduleEvent[] {
  return breaks.map(break_ => ({
    allDay: false,
    startTime: dayjs(break_.startTime).toDate(),
    endTime: dayjs(break_.endTime).toDate(),
    title: "Pauze",
    isResizable: true,
    isDraggable: true,
    __scheduledBreakId: break_.id,
    __scheduledBreakRuleId: break_.scheduledBreakRuleId,
    __appointmentId: undefined,
    __isShowingRouteOnMap: false,
    __appointment: undefined,
  }));
}

function mapAppointmentsToEvents(appointments: Record<string, string>[]): IntegratedScheduleEvent[] {
  return appointments.map(appointment => ({
    allDay: false,
    startTime: dayjs(appointment.startTime).toDate(),
    endTime: dayjs(appointment.endTime).toDate(),
    title: formatTitle(appointment),
    isResizable: false,
    isDraggable: false,
    __scheduledBreakId: undefined,
    __scheduledBreakRuleId: undefined,
    __appointmentId: appointment.id,
    __isShowingRouteOnMap: false,
    __appointment: appointment,
  }));
}

export function formatTitle(appointment: Record<string, string>) {
  const { relation, appointmentType, status } = appointment;
  const retVal = `${relation.afasCode} - ${formatAppointmentType(appointmentType)} - ${relation.name}`;

  return status === "STATUS_SCHEDULED" ? `[C] ${retVal}` : retVal;
}

const schedulingAppointmentFragment = gql`
  fragment SchedulingAppointmentFragment on Appointment {
    id
    appointmentType {
      id
      name
      category {
        id
        name
      }
    }
    relation {
      id
      name
      afasCode
    }
    location {
      id
      name
    }
    inviteSentOn
    startTime
    endTime
    status
  }
`;

const ScheduleQuery = gql`
  query ($startTime: DateTime!, $endTime: DateTime!, $employeeId: ID!) {
    appointments(startTime: $startTime, endTime: $endTime, employeeId: $employeeId, status: [STATUS_SCHEDULED, STATUS_CONFIRMED]) {
      ...SchedulingAppointmentFragment
    }

    scheduledBreaks(employeeId: $employeeId, startDate: $startTime, endDate: $endTime) {
      id
      scheduledBreakRuleId
      startTime
      endTime
      isPristine
    }
  }

  ${schedulingAppointmentFragment}
`;

const AppointmentsQuery = gql`
  query AppointmentsQuery($relationId: ID!) {
    appointments(relationId: $relationId, status: [STATUS_SCHEDULED, STATUS_CONFIRMED]) {
      ...SchedulingAppointmentFragment
    }
  }

  ${schedulingAppointmentFragment}
`;
