import "./index.css";

import { ApolloProvider } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { createRoot } from "react-dom/client";

import LocaleProvider from "@/components/LocaleProvider";
import { BACKEND_URL, ENVIRONMENT, SENTRY_DSN } from "@/constants";

import DefibrionApp from "./App";
import { AuthenticationContextProvider } from "./authentication";
import { FileModal } from "./components/FileModal";
import configureClient from "./configure-apollo";

Sentry.init({
  enabled: ENVIRONMENT === "prod",
  dsn: SENTRY_DSN,
  tracesSampleRate: 0.0,
});

// eslint-disable-next-line prettier/prettier
const client = configureClient(BACKEND_URL + "/graphql/employees/batch", BACKEND_URL + "/graphql/employees");

function createSentryDialogOptions() {
  return { user: { email: localStorage.getItem("userEmail") ?? undefined } };
}

function App() {
  return (
    <Sentry.ErrorBoundary fallback={<span>Woops! Something went wrong</span>} showDialog dialogOptions={createSentryDialogOptions}>
      <ApolloProvider client={client}>
        <AuthenticationContextProvider>
          <LocaleProvider>
            <DefibrionApp />
            <FileModal.Root />
          </LocaleProvider>
        </AuthenticationContextProvider>
      </ApolloProvider>
    </Sentry.ErrorBoundary>
  );
}

const container = document.getElementById("root");
const root = createRoot(container as HTMLElement);

root.render(<App />);
