import { CloseOutlined, UndoOutlined } from "@ant-design/icons";
import { ApolloError, gql, useMutation, useQuery } from "@apollo/client";
import { Button, Form, Input, notification, Skeleton, Switch } from "antd";
import dayjs from "dayjs";
import { RRule } from "rrule";

import AppointmentTypePicker from "@/components/AppointmentTypePicker";
import { Modal } from "@/components/Modal";
import MonthPicker from "@/components/MonthPicker";
import { createChangedFormValues } from "@/functions/create-changed-form-values";
import mapGraphQLErrorsToNotifications from "@/functions/map-graphql-errors-to-notifications";

import ContractVisitsQuery from "../graphql/ContractVisitsQuery";
import { mapFlagsToFormValues } from "../mapFlagsToFormValues";
import { mapFormValuesToFlags, removeFlagsFromFormValues } from "../mapFormValuesToFlags";
import { FlagsInputBlock } from "./FlagsInputBlock";
import FrequencySelect from "./FrequencySelect";
import MonthInYearPicker from "./MonthInYearPicker";
import RecurrencesPreviewTable from "./RecurrencesPreviewTable";
import RescheduleFromPicker from "./RescheduleFromPicker";

function returnAsArray<T>(value: T | T[] | undefined | null) {
  if (!value) return [];
  return Array.isArray(value) ? value : [value];
}

interface UpdateSingleVisitModalProps {
  contractId: string;
  onCancelVisit: () => void;
  onExtendVisit: () => void;
  onClose: () => void;
  visitId: string;
}

export default function UpdateSingleVisitModal({
  contractId,
  onCancelVisit,
  onExtendVisit,
  onClose,
  visitId,
}: UpdateSingleVisitModalProps) {
  const [formInstance] = Form.useForm();
  const { data } = useQuery(ContractVisitsQuery, { variables: { contractId } });
  const [updateVisitAsync, { loading: isSubmitting }] = useMutation(UpdateSingleVisitMutation);

  const handleOnSubmit = async values => {
    const changedProps = createChangedFormValues(values, formInstance.isFieldTouched);

    // not picked up by `isFieldTouched` if not changed from its initialValue
    if (values.rescheduleFrom) {
      changedProps.rescheduleFrom = values.rescheduleFrom;
    }

    if (Object.keys(changedProps).length > 0) {
      const [flags_, isFlagsUpdated] = mapFormValuesToFlags(values, "flags", formInstance.isFieldsTouched);
      const flags = isFlagsUpdated ? flags_ : undefined;

      if (changedProps.interval || changedProps.repeat) {
        changedProps.recurrence = new RRule({ interval: values.interval, bymonth: values.repeat, freq: RRule.YEARLY }).toString();

        delete changedProps.interval;
        delete changedProps.repeat;
      }

      try {
        await updateVisitAsync({ variables: { contractId, visitId, flags, ...removeFlagsFromFormValues(changedProps) } });
        notification.success({ message: "Bezoek is aangepast" });

        onClose();
      } catch (error) {
        mapGraphQLErrorsToNotifications(error as ApolloError);
      }
    }
  };

  const visit = data?.contract.visits.find(v => v.id === visitId) ?? undefined;
  const startingOn = undefined !== visit ? dayjs(visit.startingOn) : undefined;
  const endingOn = visit?.endingOn ? dayjs(visit.endingOn) : undefined;

  const isStartingOnEditable = undefined !== startingOn ? startingOn.isSame(dayjs()) || startingOn.isAfter(dayjs()) : false;
  const _repeat = Form.useWatch("repeat", formInstance);
  const _interval = Form.useWatch("interval", formInstance);
  const _startingOn = Form.useWatch("startingOn", formInstance);
  const _rescheduleFrom = Form.useWatch("rescheduleFrom", formInstance) ?? "CURRENT_CALENDAR_YEAR";
  const _overrideDefaultFlags = Form.useWatch("overrideDefaultFlags", formInstance);

  // eslint-disable-next-line prettier/prettier
  const isRecurrenceChanged =
    formInstance.isFieldTouched("startingOn") || formInstance.isFieldTouched("interval") || formInstance.isFieldTouched("repeat");

  return (
    <Modal
      bodyStyle={{
        overflowY: "auto",
        maxHeight: window.innerHeight - 168,
      }}
      centered
      footer={
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {undefined === endingOn ? (
            <Button key="delete" onClick={onCancelVisit} danger icon={<CloseOutlined />}>
              Stopzetten
            </Button>
          ) : (
            <Button key="extend" onClick={onExtendVisit} danger icon={<UndoOutlined />}>
              Einddatum aanpassen
            </Button>
          )}
          <div>
            <Button key="cancel" onClick={onClose}>
              Annuleer
            </Button>
            <Button key="ok" loading={isSubmitting} onClick={() => formInstance.submit()} type="primary">
              OK
            </Button>
          </div>
        </div>
      }
      onCancel={onClose}
      title={undefined !== visit ? `Bezoek aanpassen - ${visit.location.name}` : "-"}
      open
      width="30%"
    >
      {undefined === visit ? (
        <Skeleton active />
      ) : (
        <Form
          form={formInstance}
          initialValues={{
            locationId: visit.location.id,
            appointmentTypeId: visit.appointmentType.id,
            startingOn: startingOn,
            interval: RRule.parseString(visit.recurrence).interval ?? 1,
            repeat: returnAsArray(RRule.parseString(visit.recurrence).bymonth).map(index => index.toString()) ?? [],
            comment: visit.comment,
            overrideDefaultFlags: visit.overrideDefaultFlags,
            ...mapFlagsToFormValues(visit.flags, "flags"),
          }}
          onFinish={handleOnSubmit}
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 17 }}
        >
          <Form.Item
            label="Eerste afspraak"
            name="startingOn"
            extra={!isStartingOnEditable ? "Bezoek al begonnen, startdatum niet meer aanpasbaar" : undefined}
          >
            <MonthPicker disabled={!isStartingOnEditable} format="MMMM, YYYY" style={{ width: "100%" }} />
          </Form.Item>
          {undefined !== endingOn && (
            <Form.Item label="Eindigen op">
              <MonthPicker disabled format="MMMM, YYYY" value={endingOn} style={{ width: "100%" }} />
            </Form.Item>
          )}
          <Form.Item label="Soort afspraak" name="appointmentTypeId" rules={[{ required: true }]}>
            <AppointmentTypePicker
              preloadWithAppointmentType={{ ...visit.appointmentType, parentCategoryId: visit.appointmentType.category.id }}
            />
          </Form.Item>
          <Form.Item label="Frequentie" name="interval" rules={[{ required: true }]}>
            <FrequencySelect />
          </Form.Item>
          <Form.Item label="Herhalen in" name="repeat" rules={[{ required: true }]}>
            <MonthInYearPicker mode={Array.isArray(_repeat) && _repeat.length > 1 ? "multiple" : undefined} />
          </Form.Item>
          <Form.Item name="overrideDefaultFlags" label="Andere instellingen" rules={[{ required: true }]}>
            <Switch />
          </Form.Item>
          {_overrideDefaultFlags && <FlagsInputBlock prefix="flags" />}
          {isRecurrenceChanged && (
            <Form.Item initialValue="CURRENT_CALENDAR_YEAR" label="Aanpassen per" name="rescheduleFrom" rules={[{ required: true }]}>
              <RescheduleFromPicker />
            </Form.Item>
          )}
          <Form.Item label="Opmerking" name="comment">
            <Input.TextArea rows={3} />
          </Form.Item>
          {undefined !== _startingOn && undefined !== _repeat && undefined !== _interval && (
            <RecurrencesPreviewTable
              visitId={visit.id}
              rescheduleFrom={_rescheduleFrom}
              startingOn={_startingOn}
              endingOn={endingOn ? endingOn : undefined}
              repeat={_repeat}
              interval={_interval}
              isRecurrenceChanged={isRecurrenceChanged}
            />
          )}
        </Form>
      )}
    </Modal>
  );
}

const UpdateSingleVisitMutation = gql`
  mutation (
    $contractId: ID!
    $visitId: ID!
    $rescheduleFrom: RescheduleRecurrencesFromMode
    $startingOn: DateTime
    $appointmentTypeId: ID
    $recurrence: String
    $comment: String
    $overrideDefaultFlags: Boolean
    $flags: [MaintenanceContractFlag!]
  ) {
    updateVisit(
      input: {
        contractId: $contractId
        visitId: $visitId
        rescheduleFrom: $rescheduleFrom
        startingOn: $startingOn
        appointmentTypeId: $appointmentTypeId
        recurrence: $recurrence
        comment: $comment
        overrideDefaultFlags: $overrideDefaultFlags
        flags: $flags
      }
    ) {
      contract {
        id
        visits {
          id
          location {
            id
            name
          }
          appointmentType {
            id
            name
            deletedAt
            category {
              id
              name
              deletedAt
            }
          }
          comment
          startingOn
          endingOn
          recurrence
          overrideDefaultFlags
          flags
        }
      }
    }
  }
`;
