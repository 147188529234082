import { Card } from "antd";
import * as React from "react";

import { downloadFile } from "@/functions/download-file";

import DownloadButton from "./DownloadButton";

export default function IssuesSpreadsheet() {
  return (
    <Card title="Alle meldingen">
      <DownloadButton type="primary" onClick={() => downloadFile("reports/issues")}>
        Downloaden
      </DownloadButton>
    </Card>
  );
}
