import { ApolloError, gql, useMutation, useQuery } from "@apollo/client";
import { Checkbox, DatePicker, Form, notification, Skeleton, Slider } from "antd";
import dayjs from "dayjs";
import { useMemo } from "react";
import { RRule, rrulestr } from "rrule";

import EmployeePicker from "@/components/EmployeePicker";
import { Modal } from "@/components/Modal";
import TimePicker from "@/components/TimePicker";
import filterTouchedValues from "@/functions/filter-touched-values";
import mapGraphQLErrorsToNotifications from "@/functions/map-graphql-errors-to-notifications";

interface UpdateScheduledBreakRuleModalProps {
  scheduledBreakRuleId: string;
  onClose: () => void;
}

const DAYS_OF_WEEK = [
  { label: "Maandag", value: RRule.MO.weekday },
  { label: "Dinsdag", value: RRule.TU.weekday },
  { label: "Woensdag", value: RRule.WE.weekday },
  { label: "Donderdag", value: RRule.TH.weekday },
  { label: "Vrijdag", value: RRule.FR.weekday },
];

export default function UpdateScheduledBreakRuleModal({ scheduledBreakRuleId, onClose }: UpdateScheduledBreakRuleModalProps) {
  const [form] = Form.useForm();

  const scheduledBreakRuleQuery = useQuery(ScheduledBreakRuleQuery, {
    variables: { scheduledBreakRuleId },
  });

  const [updateScheduledBreakRuleAsync, { loading: isUpdateLoading }] = useMutation(UpdateScheduledBreakRuleMutation);

  const handleOnSubmit = async (_values: typeof initialValues) => {
    if (_values === undefined) return;

    const updatedProps = filterTouchedValues(form.isFieldTouched, _values);
    if (Object.keys(updatedProps).length < 1) return onClose();

    if (updatedProps.recurrence !== undefined && (updatedProps.recurrence as unknown as number[]).length < 1) {
      return notification.error({ message: "Selecteer tenminste 1 werkdag" });
    }

    try {
      if ("recurrence" in updatedProps) {
        const prevValue = rrulestr(scheduledBreakRuleQuery.data?.scheduledBreakRule.recurrence);
        const recurrence = new RRule({
          ...prevValue.origOptions,
          byweekday: updatedProps.recurrence,
        });

        updatedProps.recurrence = recurrence.toString();
      }

      await updateScheduledBreakRuleAsync({
        variables: {
          ...updatedProps,
          duration: "duration" in updatedProps ? "P00Y00M00DT00H" + updatedProps["duration"] + "M00S" : undefined,
          scheduledBreakRuleId,
        },
      });

      onClose();
    } catch (error) {
      mapGraphQLErrorsToNotifications(error as ApolloError);
    }
  };

  const initialValues = useMemo(() => {
    const scheduledBreakRule = scheduledBreakRuleQuery.data?.scheduledBreakRule;

    if (scheduledBreakRule === undefined) {
      return undefined;
    }

    const DURATION_REGEX = /T.*(?<minutes>\d{2})M/;
    const duration = DURATION_REGEX.exec(scheduledBreakRule.duration)?.[1];
    const rruleParsed = rrulestr(scheduledBreakRule.recurrence);

    return {
      duration,
      employeeId: scheduledBreakRule.employee.id,
      validFrom: dayjs(scheduledBreakRule.validFrom),
      startTime: dayjs(scheduledBreakRule.validFrom)
        .set("hour", rruleParsed.options.byhour?.[0])
        .set("minute", rruleParsed.options.byminute?.[0]),
      validUntil: scheduledBreakRule.validUntil ? dayjs(scheduledBreakRule.validUntil) : null,
      recurrence: rruleParsed.options.byweekday,
    };
  }, [scheduledBreakRuleQuery.data]);

  const isStarted = initialValues?.validFrom ? dayjs().isAfter(initialValues.validFrom) : false;
  const isEnded = initialValues?.validUntil ? dayjs().isAfter(initialValues.validUntil) : false;

  return (
    <Modal confirmLoading={isUpdateLoading} onCancel={onClose} onOk={() => form.submit()} open title="Pauzeschema regel aanpassen">
      {scheduledBreakRuleQuery.data === undefined ? (
        <Skeleton />
      ) : (
        <Form form={form} onFinish={handleOnSubmit} layout="vertical" initialValues={initialValues}>
          <Form.Item name="employeeId" label="Medewerker" required rules={[{ required: true }]}>
            <EmployeePicker disabled />
          </Form.Item>
          <Form.Item
            label="Starten per"
            style={{ marginBottom: 0 }}
            extra={isStarted ? "Regel is al gestart en kan niet meer aangepast worden" : undefined}
          >
            <Form.Item name="validFrom" style={{ display: "inline-block" }} required rules={[{ required: true }]}>
              <DatePicker disabled={isStarted} />
            </Form.Item>
            <span style={{ display: "inline-block", width: "32px", lineHeight: "32px", textAlign: "center" }}>om</span>
            <Form.Item name="startTime" style={{ display: "inline-block" }} required rules={[{ required: true }]}>
              <TimePicker disabled={isStarted} hideDisabledOptions format="HH:mm" />
            </Form.Item>
          </Form.Item>
          <Form.Item
            name="validUntil"
            label="Geldig tot"
            required={initialValues?.validUntil !== null}
            rules={[{ required: initialValues?.validUntil !== null }]}
          >
            <DatePicker disabled={isEnded} />
          </Form.Item>
          <Form.Item name="recurrence" label="Werkdagen" required rules={[{ required: true }]}>
            <Checkbox.Group options={DAYS_OF_WEEK} />
          </Form.Item>
          <Form.Item name="duration" label="Pauze (in minuten)" required rules={[{ required: true }]}>
            <Slider min={0} max={120} />
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
}

const ScheduledBreakRuleQuery = gql`
  query ($scheduledBreakRuleId: ID!) {
    scheduledBreakRule(id: $scheduledBreakRuleId) {
      id
      employee {
        id
        email
      }
      recurrence
      duration
      validFrom
      validUntil
      plannedUntil
    }
  }
`;

const UpdateScheduledBreakRuleMutation = gql`
  mutation ($scheduledBreakRuleId: ID!, $recurrence: String, $duration: DateInterval, $validFrom: DateTime, $validUntil: DateTime) {
    updateScheduledBreakRule(
      input: {
        scheduledBreakRuleId: $scheduledBreakRuleId
        recurrence: $recurrence
        duration: $duration
        validFrom: $validFrom
        validUntil: $validUntil
      }
    ) {
      scheduledBreakRule {
        id
        recurrence
        duration
        validFrom
        validUntil
        plannedUntil
      }
    }
  }
`;
