import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";

import { ApolloError, useMutation } from "@apollo/client";
import { Space } from "antd";
import dayjs from "dayjs";
import times from "lodash/times";
import { useMemo, useState } from "react";
import { Calendar, dayjsLocalizer, Views } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";

import AppointmentModal from "@/components/AppointmentModal";
import mapGraphQLErrorsToNotifications from "@/functions/map-graphql-errors-to-notifications";
import { useStorageState } from "@/hooks/use-storage-state";

import UpdateScheduledBreakMutation from "../graphql/UpdateScheduledBreakMutation";
import { AgendaEvent } from "./AgendaEvent";
import { BulkEditContextProvider } from "./BulkEditContext";
import { BulkEditToolbar } from "./BulkEditToolbar";
import RelationAgenda from "./RelationAgenda";
import ScheduleToolbar from "./ScheduleToolbar";
import { IntegratedScheduleEvent, useAppointmentSchedule } from "./use-appointment-schedule";

const DragAndDropCalendar = withDragAndDrop<IntegratedScheduleEvent>(Calendar);

export default function AppointmentSchedule() {
  const [startDate, setStartDate] = useStorageState("@SCHEDULER/DATE", mapDateToString, mapStringToDate);
  const [employeeId, setEmployeeId] = useStorageState("@SCHEDULER/EMPLOYEE", undefined, x => x ?? undefined);
  const endDate = useMemo(() => dayjs(startDate).endOf("week").toDate(), [startDate]);
  const [selectedView, setSelectedView] = useState<(typeof Views)[keyof typeof Views]>(Views.WORK_WEEK);
  const [appointmentId, setAppointmentId] = useState<string>();
  const [relationId, setRelationId] = useState<string>();

  const { loading, events, refetch } = useAppointmentSchedule(startDate, endDate, employeeId, relationId);
  const [updateScheduledBreakAsync] = useMutation(UpdateScheduledBreakMutation);

  const handleUpdateScheduledBreak = async (scheduledBreakRuleId: string, scheduledBreakId: string, startTime: Date, endTime: Date) => {
    if (scheduledBreakId === undefined) {
      return; //
    }

    try {
      await updateScheduledBreakAsync({
        variables: {
          scheduledBreakRuleId,
          scheduledBreakId,
          startTime,
          endTime,
        },
        optimisticResponse: {
          updateScheduledBreak: {
            scheduledBreak: {
              id: scheduledBreakId,
              startTime,
              endTime,
              isPristine: false,
              __typename: "ScheduledBreak",
            },
            __typename: "UpdateScheduledBreakPayload",
          },
        },
      });
    } catch (error) {
      mapGraphQLErrorsToNotifications(error as ApolloError);
    }
  };

  const handleOnSelectEvent = (event: IntegratedScheduleEvent) => {
    if (event.__appointmentId !== undefined) {
      setAppointmentId(event.__appointmentId);
    } else if (event.__isShowingRouteOnMap) {
      window.open(`/scheduler?routeEmployeeId=${employeeId}&routeDate=${dayjs(event.startTime).format("YYYY-MM-DD")}`, "_blank");
    }
  };

  const ALL_DAY_DUMMIES: IntegratedScheduleEvent[] = useMemo(
    () =>
      times(5).map((_value, value) => {
        return {
          allDay: true,
          // eslint-disable-next-line prettier/prettier
          startTime: dayjs(startDate)
            .add(value, "days")
            .toDate(),
          endTime: dayjs(startDate)
            .add(value + 1, "days")
            .toDate(),
          title: "Route weergeven op kaart",
          isResizable: false,
          isDraggable: false,
          __scheduledBreakId: undefined,
          __scheduledBreakRuleId: undefined,
          __appointmentId: undefined,
          __isShowingRouteOnMap: true,
          __appointment: undefined,
        };
      }),
    [startDate]
  );

  const eventsWithDummies = useMemo(() => {
    if (relationId !== undefined) return events;
    return selectedView === Views.WORK_WEEK ? [...events, ...ALL_DAY_DUMMIES] : events;
  }, [events, selectedView]);

  return (
    <BulkEditContextProvider>
      {/* eslint-disable-next-line prettier/prettier */}
      {undefined !== appointmentId && (
        <AppointmentModal
          appointmentId={appointmentId}
          onClose={() => setAppointmentId(undefined)}
        />
      )}
      <Space direction="vertical">
        <ScheduleToolbar
          date={startDate}
          employeeId={employeeId}
          onChangeDate={value => setStartDate(value)}
          onChangeEmployeeId={value => setEmployeeId(value)}
          onChangeRelationId={value => setRelationId(value)}
          onChangeView={value => setSelectedView(value)}
          onReload={() => refetch()}
          relationId={relationId}
          reloading={loading}
          view={selectedView}
        />
        {relationId !== undefined ? (
          <RelationAgenda
            endAccessor={event => event.endTime}
            events={eventsWithDummies}
            onSelectEvent={handleOnSelectEvent}
            startAccessor={event => event.startTime}
          />
        ) : (
          <>
            {selectedView === Views.AGENDA && <BulkEditToolbar />}

            <DragAndDropCalendar
              components={CUSTOM_COMPONENTS}
              date={startDate}
              draggableAccessor={event => event.isDraggable}
              endAccessor={event => event.endTime}
              events={eventsWithDummies}
              localizer={LOCALIZER}
              min={START_OF_DAY}
              max={END_OF_DAY}
              messages={{ allDay: undefined }}
              onSelectEvent={handleOnSelectEvent}
              onEventDrop={({ event, start, end }) => {
                if (event.__scheduledBreakRuleId === undefined || event.__scheduledBreakId === undefined) return;
                handleUpdateScheduledBreak(event.__scheduledBreakRuleId, event.__scheduledBreakId, start, end);
              }}
              onEventResize={({ event, start, end }) => {
                if (event.__scheduledBreakRuleId === undefined || event.__scheduledBreakId === undefined) return;
                handleUpdateScheduledBreak(event.__scheduledBreakRuleId, event.__scheduledBreakId, start, end);
              }}
              startAccessor={event => event.startTime}
              resizable
              resizableAccessor={event => event.isResizable}
              step={30}
              style={{ width: "100%", height: "100%", backgroundColor: "white" }}
              toolbar={false}
              views={[Views.WORK_WEEK, Views.AGENDA]}
              view={selectedView}
            />
          </>
        )}
      </Space>
    </BulkEditContextProvider>
  );
}

const LOCALIZER = dayjsLocalizer(dayjs);

const START_OF_DAY = new Date();
START_OF_DAY.setHours(7, 0);

const END_OF_DAY = new Date();
END_OF_DAY.setHours(18, 0);

const CUSTOM_COMPONENTS = { agenda: { event: AgendaEvent } };

function mapStringToDate(input: string | null) {
  return (null !== input ? dayjs(input) : dayjs().startOf("week")).toDate();
}

function mapDateToString(input: Date) {
  return input.toISOString();
}
