import { notification } from "antd";
import { noop } from "lodash";
import React, { createContext, useContext, useMemo, useState } from "react";

import { useSendInvites } from "./use-send-invites";

type InviteType = "exact" | "undetermined" | "time-window";

interface BulkEditContextValue {
  mode: "inactive" | "inviting";
  loading: boolean;
  inviteTypePerAppointment: Map<string, Exclude<InviteType, "undetermined">>;
  startInviteMode: () => void;
  cancelInviteMode: () => void;
  confirmInviteMode: () => void;
  setInviteType: (appointmentId: string, inviteType: InviteType) => void;
  getInviteTypeForAppointment: (appointmentId: string) => InviteType;
  numberOfInvites: () => number;
}

const bulkEditContext = createContext<BulkEditContextValue>({
  mode: "inactive",
  loading: false,

  // batch-sending invites
  inviteTypePerAppointment: new Map(),
  cancelInviteMode: noop,
  confirmInviteMode: noop,
  startInviteMode: noop,
  setInviteType: noop,
  getInviteTypeForAppointment: () => "undetermined",
  numberOfInvites: () => 0,
});

interface BulkEditContextProviderProps {
  children: React.ReactNode;
}

export function BulkEditContextProvider({ children }: BulkEditContextProviderProps) {
  const sendBatchInvites = useSendInvites();
  const [notifications] = notification.useNotification();

  const [state, setState] = useState({
    mode: "inactive",
    inviteTypePerAppointment: new Map<string, Exclude<InviteType, "undetermined">>(),
  });

  const handleStartInviteMode = () => {
    setState(current => ({ ...current, mode: "inviting" }));
  };

  const handleSetInviteType = (appointmentId: string, inviteType: InviteType) => {
    setState(current => {
      const map = current.inviteTypePerAppointment;

      if (inviteType === "undetermined") {
        map.delete(appointmentId);
      } else {
        map.set(appointmentId, inviteType);
      }

      return { ...current, inviteTypePerAppointment: map };
    });
  };

  const handleCancelInviteMode = () => {
    setState(current => ({ ...current, mode: "inactive", inviteTypePerAppointment: new Map() }));
  };

  const handleConfirmInviteMode = async () => {
    if (state.mode !== "inviting") return;

    try {
      setState(current => ({ ...current, loading: true }));
      const responses = await sendBatchInvites(state.inviteTypePerAppointment);

      const numberOfFailures = responses.filter(x => x.status === "rejected").length;
      if (numberOfFailures > 0) {
        notification.warning({
          message: "Niet alle uitnodigingen konden verstuurd worden. Probeer deze afspraken los te behandelen",
        });
      } else {
        notification.success({
          message: "Alle uitnodigingen zijn verstuurd",
        });

        handleCancelInviteMode();
      }
    } catch (error) {
      notifications.error({
        message: "Oeps! Er ging iets mis tijdens het versturen van de uitnodigingen",
      });
    } finally {
      setState(current => ({ ...current, loading: false }));
    }
  };

  const getInviteTypeForAppointment = (appointmentId: string) => {
    return state.inviteTypePerAppointment.get(appointmentId) ?? "undetermined";
  };

  const numberOfInvites = () => {
    return state.inviteTypePerAppointment.size;
  };

  const contextValue = useMemo(
    () => ({
      ...state,
      getInviteTypeForAppointment,
      numberOfInvites,
      cancelInviteMode: handleCancelInviteMode,
      startInviteMode: handleStartInviteMode,
      setInviteType: handleSetInviteType,
      confirmInviteMode: handleConfirmInviteMode,
    }),
    [state]
  );

  return <bulkEditContext.Provider value={contextValue}>{children}</bulkEditContext.Provider>;
}

export function useBulkEditContext() {
  return useContext(bulkEditContext);
}
