import { Card, DatePicker } from "antd";
import dayjs from "dayjs";
import * as React from "react";

import RelationPicker from "@/components/RelationPicker";
import { downloadFile } from "@/functions/download-file";

import DownloadButton from "./DownloadButton";

export default function AppointmentsForRelationSpreadsheet() {
  const [{ start, end }, setPeriod] = React.useState<Record<string, dayjs.Dayjs | null | undefined>>({ start: null, end: null });
  const [relationId, setRelationId] = React.useState<string | null>(null);

  const handleOnDownload = () => {
    if (!start || !end || !relationId) return Promise.resolve();

    return downloadFile(
      `reports/appointments-relation/${relationId}?start-date=${start.format("YYYY-MM-DD")}&end-date=${end.format("YYYY-MM-DD")}`
    );
  };

  return (
    <Card title="Overzicht afspraken per relatie">
      <div>
        <span>Relatie: </span>
        <RelationPicker onChange={value => setRelationId(value as string)} style={{ width: 300 }} />
      </div>

      <div style={{ marginTop: 5, marginBottom: 5 }}>
        <span>Periode: </span>
        <DatePicker.RangePicker
          onChange={period => {
            setPeriod(curr => ({
              start: period?.[0] ?? curr[0],
              end: period?.[1] ?? curr[1],
            }));
          }}
        />
      </div>

      <DownloadButton type="primary" onClick={handleOnDownload} disabled={!start || !end || !relationId}>
        Downloaden
      </DownloadButton>
    </Card>
  );
}
