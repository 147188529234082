import { gql, useMutation, useQuery } from "@apollo/client";
import { Form, Input, notification, Skeleton } from "antd";

import MarkdownTextInput from "@/components/MarkdownTextInput";
import { Modal } from "@/components/Modal";
import RelationPicker from "@/components/RelationPicker";

import { RelationQuery } from "../../graphql/RelationQuery";

interface UpdateRelationModalProps {
  onClose: () => void;
  relationId: string;
}

const UpdateMutation = gql`
  mutation ($input: UpdateRelationInput!) {
    updateRelation(input: $input) {
      relation {
        id
        name
        place
        afasCode
        dealer {
          id
          name
          afasCode
        }
        planningComment
        onSiteComment
        invoiceComment
      }
    }
  }
`;

interface InputFields {
  afasCode: string;
  dealerId: number | null;
  planningComment: string | null;
  onSiteComment: string | null;
  invoiceComment: string | null;
}

export default function UpdateRelationModal({ onClose, relationId }: UpdateRelationModalProps) {
  const [formInstance] = Form.useForm();

  const { data } = useQuery(RelationQuery, { variables: { id: relationId } });
  const [update, { loading: updating }] = useMutation(UpdateMutation);

  const handleOnSubmit = async ({ afasCode, dealerId, planningComment, onSiteComment, invoiceComment }: InputFields) => {
    try {
      await update({
        variables: {
          input: {
            id: relationId,
            afasCode,
            dealerId,
            planningComment: "" !== planningComment ? planningComment : null,
            onSiteComment: "" !== onSiteComment ? onSiteComment : null,
            invoiceComment: "" !== invoiceComment ? invoiceComment : null,
          },
        },
      });

      onClose();
      notification.success({ message: "Klantgegevens aangepast" });
    } catch (error) {
      notification.error({ message: (error as Error).message });
    }
  };

  const relation = data?.relation ?? undefined;

  return (
    <Modal confirmLoading={updating} onCancel={onClose} onOk={() => formInstance.submit()} title="Klantgegevens aanpassen" open>
      {undefined === relation ? (
        <Skeleton />
      ) : (
        <Form
          form={formInstance}
          onFinish={handleOnSubmit}
          initialValues={{
            afasCode: relation.afasCode,
            dealerId: relation.dealer?.id ?? null,
            planningComment: relation.planningComment ?? null,
            onSiteComment: relation.onSiteComment ?? null,
            invoiceComment: relation.invoiceComment ?? null,
          }}
          layout="vertical"
        >
          <Form.Item label="Naam">
            <Input disabled value={relation.name} />
          </Form.Item>
          <Form.Item label="Vestigingslocatie">
            <Input disabled value={relation.place} />
          </Form.Item>
          <Form.Item name="afasCode" label="AFAS-code">
            <Input disabled={Boolean(relation.afasCode)} />
          </Form.Item>
          <Form.Item name="dealerId" label="Dealer-klant van">
            <RelationPicker allowClear onClear={() => formInstance.setFieldValue("dealerId", null)} />
          </Form.Item>
          <Form.Item name="planningComment" label="Planning notities">
            <Input.TextArea />
          </Form.Item>
          <Form.Item name="onSiteComment" label="Buitendienst notities">
            <Input.TextArea />
          </Form.Item>
          <Form.Item name="invoiceComment" label="Facturatie notities">
            <MarkdownTextInput />
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
}
