import { gql, useMutation } from "@apollo/client";

export function useSendInvites() {
  const [sendInviteAsync] = useMutation(sendInviteMutation);

  const sendBatchInvites = (input: Map<string, "exact" | "time-window">) => {
    return Promise.allSettled(
      [...input.entries()].map(([appointmentId, inviteType]) => {
        return sendInviteAsync({
          variables: {
            appointmentId,
            displayTimeWindow: inviteType === "time-window",
            useDefaultTemplate: true,
          },
        });
      })
    );
  };

  return sendBatchInvites;
}

const sendInviteMutation = gql`
  mutation ($appointmentId: ID!, $useDefaultTemplate: Boolean!, $displayTimeWindow: Boolean!) {
    sendAppointmentInvite(
      input: { appointmentId: $appointmentId, useDefaultTemplate: $useDefaultTemplate, displayTimeWindow: $displayTimeWindow }
    ) {
      appointment {
        id
        inviteSentOn
      }
    }
  }
`;
