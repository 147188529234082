import "./AppointmentPane.css";

import {
  CheckOutlined,
  ClockCircleOutlined,
  CloseOutlined,
  CustomerServiceOutlined,
  LoadingOutlined,
  MailFilled,
  MailOutlined,
  NodeIndexOutlined,
  PhoneFilled,
  PhoneOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { ApolloError, gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Alert, Card, Col, DatePicker, Divider, Form, Input, Modal, notification, Row, Space } from "antd";
import dayjs from "dayjs";
import { isValidElement } from "react";

import AppointmentTypePicker from "@/components/AppointmentTypePicker";
import EmployeePicker from "@/components/EmployeePicker";
import MarkdownTextInput from "@/components/MarkdownTextInput";
import OptionalInformation from "@/components/OptionalInformation";
import TimePicker from "@/components/TimePicker";
import formatAddress from "@/functions/format-address";
import formatDuration from "@/functions/format-duration";
import mapGraphQLErrorsToNotifications from "@/functions/map-graphql-errors-to-notifications";
import updateDayjsDate from "@/functions/update-dayjs-date";

import CustomMarkerPopover from "../CustomMarkerPopover";
import ProductsTables from "../ProductsTables";
import { AppointmentRemindMeSidebar } from "./AppointmentRemindMeSidebar";
import InformationItem from "./InformationItem";

interface FormValues {
  employeeId?: string;
  date: dayjs.Dayjs;
  startTime?: dayjs.Dayjs;
  endTime?: dayjs.Dayjs;
  plannerComment?: string;
  onSiteComment?: string;
}

interface AppointmentPaneProps {
  appointmentId: string;
  onClose: () => void;
  onInviteClick: () => void;
  visible: boolean;
}

function AppointmentPane({ appointmentId, onClose, onInviteClick, visible }: AppointmentPaneProps) {
  const [formInstance] = Form.useForm();

  const [scheduleAppointmentMutation, { loading: scheduleAppointmentLoading }] = useMutation(ScheduleAppointmentMutation);
  const [updateAppointmentMutation, { loading: updateAppointmentLoading }] = useMutation(UpdateAppointmentMutation);
  const [unscheduleAppointmentMutation, { loading: unscheduleAppointmentLoading }] = useMutation(UnscheduleAppointmentMutation);
  const [updateLocationMutation] = useMutation(UpdateLocationMutation);
  const [loadPriorAppointments] = useLazyQuery(PriorAppointmentsQuery);

  const { data, loading } = useQuery(AppointmentQuery, { variables: { appointmentId } });
  const appointment = data?.appointment;

  const handleOnScheduleAppointmentClick = () => {
    if (undefined === appointment) return;

    const handleScheduleAppointment = async (values: Required<FormValues>) => {
      try {
        await Promise.all([
          scheduleAppointmentMutation({
            variables: {
              id: appointment.id,
              startTime: values.startTime,
              endTime: values.endTime,
              employeeId: values.employeeId,
              onSiteComment: formInstance.isFieldTouched("appointmentOnSiteComment") ? values.onSiteComment : undefined,
              plannerComment: formInstance.isFieldTouched("appointmentPlannerComment") ? values.plannerComment : undefined,
            },
          }),
          handleMaybeUpdateLocationProps(values),
        ]);

        notification.success({ message: "Afspraak is ingeroosterd" });
      } catch (error) {
        notification.error({ message: (error as Error).message });
      }
    };

    formInstance
      .validateFields()
      .then(async (values: Required<FormValues>) => {
        if (!values.employeeId || !values.endTime || !values.startTime) {
          return notification.warning({ message: "Niet alle velden zijn ingevuld" });
        }

        const dateProps = {
          year: values.date.year(),
          month: values.date.month(),
          date: values.date.date(),
        };

        const startTime = updateDayjsDate(values.startTime, dateProps);
        const endTime = updateDayjsDate(values.endTime, dateProps);

        const response = await loadPriorAppointments({
          variables: {
            locationId: appointment.location.id,
            beforeDate: startTime,
            afterDate: dayjs(startTime).add(-8, "months"),
            status: ["STATUS_SCHEDULED", "STATUS_CONFIRMED"],
          },
        });

        const hasPriorAppointmentsWithinTimeFrame = response.data?.appointments.length > 0;
        if (!hasPriorAppointmentsWithinTimeFrame) return handleScheduleAppointment({ ...values, startTime, endTime });

        return new Promise(resolve => {
          Modal.confirm({
            title: "Let op! Klant is korter dan 8 maanden geleden bezocht. Toch inplannen?",
            okText: "Ja",
            cancelText: "Nee",
            onOk: () => resolve(handleScheduleAppointment({ ...values, startTime, endTime })),
          });
        });
      })
      .catch(_errorInfo => {
        notification.warning({ message: "Niet alle velden zijn ingevuld" });
      });
  };

  const handleOnUnscheduleAppointmentClick = async () => {
    if (undefined === appointment) return;

    try {
      await unscheduleAppointmentMutation({
        variables: {
          id: appointment.id,
        },
      });

      notification.success({ message: "Afspraak is uitgeroosterd" });
    } catch (error) {
      mapGraphQLErrorsToNotifications(error as ApolloError);
    }
  };

  const handleMaybeUpdateLocationProps = async values => {
    const locationPropsWasUpdated =
      formInstance.isFieldTouched("locationPlanningComment") || formInstance.isFieldTouched("locationOnSiteComment");

    return locationPropsWasUpdated
      ? updateLocationMutation({
          variables: {
            relationId: appointment.relation.id,
            locationId: appointment.location.id,
            planningComment: formInstance.isFieldTouched("locationPlanningComment") ? values.locationPlanningComment : undefined,
            onSiteComment: formInstance.isFieldTouched("locationOnSiteComment") ? values.locationOnSiteComment : undefined,
          },
        })
      : Promise.resolve();
  };

  const handleOnSaveChanges = async values => {
    if (scheduleAppointmentLoading || unscheduleAppointmentLoading) return;

    const dateProps = {
      year: values.date.year(),
      month: values.date.month(),
      date: values.date.date(),
    };

    const startTime = values.startTime ? updateDayjsDate(values.startTime, dateProps) : undefined;
    const endTime = values.endTime ? updateDayjsDate(values.endTime, dateProps) : undefined;

    try {
      await Promise.all([
        updateAppointmentMutation({
          variables: {
            id: appointment.id,
            startTime: formInstance.isFieldTouched("date") || formInstance.isFieldTouched("startTime") ? startTime : undefined,
            endTime: formInstance.isFieldTouched("date") || formInstance.isFieldTouched("endTime") ? endTime : undefined,
            employeeId: formInstance.isFieldTouched("employeeId") ? values.employeeId : undefined,
            onSiteComment: formInstance.isFieldTouched("appointmentOnSiteComment") ? values.appointmentOnSiteComment : undefined,
            plannerComment: formInstance.isFieldTouched("appointmentPlannerComment") ? values.appointmentPlannerComment : undefined,
          },
        }),
        handleMaybeUpdateLocationProps(values),
      ]);

      notification.success({ message: "Afspraak is gewijzigd" });
    } catch (error) {
      notification.error({ message: (error as Error).message });
    }
  };

  return visible ? (
    <Card
      actions={
        undefined !== appointment
          ? [
              appointment.status === "STATUS_REQUESTED" ? (
                <span onClick={handleOnScheduleAppointmentClick} key="schedule" role="button">
                  {scheduleAppointmentLoading ? <LoadingOutlined /> : <ClockCircleOutlined />}
                  Inroosteren
                </span>
              ) : (
                <span onClick={handleOnUnscheduleAppointmentClick} key="unschedule" role="button">
                  {unscheduleAppointmentLoading ? <LoadingOutlined /> : <ClockCircleOutlined />}
                  Uitroosteren
                </span>
              ),
              appointment.status === "STATUS_SCHEDULED" && appointment.appointmentType.hasEmailTemplate && (
                <span onClick={null === appointment.inviteSentOn ? onInviteClick : undefined}>
                  <MailOutlined />
                  {null === appointment.inviteSentOn ? "Versturen" : "Verstuurd"}
                </span>
              ),
              <span onClick={() => formInstance.submit()} key="save-changes" role="button">
                {updateAppointmentLoading ? <LoadingOutlined /> : <CheckOutlined />}
                Opslaan
              </span>,
              <CustomMarkerPopover key="change-marker" appointment={appointment} />,
            ].filter(element => isValidElement(element))
          : []
      }
      loading={loading}
      hoverable
      bodyStyle={{
        overflow: "auto",
        maxHeight: window.innerHeight - 2 * 10 - 60 - 10,
        paddingInline: 20,
      }}
      style={{
        position: "absolute",
        borderRadius: 8,
        zIndex: 1000,
        top: 10,
        right: 10,
        width: "30%",
      }}
    >
      {appointment && (
        <Form
          form={formInstance}
          className="appointment-pane"
          layout="vertical"
          onFinish={handleOnSaveChanges}
          initialValues={{
            employeeId: appointment.employee?.id || undefined,
            date: dayjs(appointment.startTime ?? appointment.prospectiveDate),
            startTime: appointment.startTime ? dayjs(appointment.startTime) : undefined,
            endTime: appointment.endTime ? dayjs(appointment.endTime) : undefined,
            locationPlanningComment: appointment.location.planningComment,
            locationOnSiteComment: appointment.location.onSiteComment,
            appointmentPlannerComment: appointment.plannerComment,
            appointmentOnSiteComment: appointment.onSiteComment,
          }}
        >
          <div style={{ position: "absolute", top: 25, right: 25, zIndex: 1001 }}>
            <CloseOutlined onClick={onClose} />
          </div>
          <h4 style={{ marginBottom: 0 }}>
            {`${appointment.relation.afasCode ? `(${appointment.relation.afasCode})` : ""} ${appointment.relation.name}`.trim()}
          </h4>
          <div className="ant-steps-item-description" style={{ marginBottom: 8 }}>
            {appointment.relation.name !== appointment.location.name && <div>{appointment.location.name}</div>}
            <div>{formatAddress(appointment.location.address)}</div>
          </div>
          <Row justify="space-between" style={{ marginBottom: 10 }}>
            <Col span={24}>
              {appointment.relation.dealer && (
                <InformationItem
                  icon={<NodeIndexOutlined />}
                  value={`Dealer-klant: (${appointment.relation.dealer.afasCode}) ${appointment.relation.dealer.name}`}
                />
              )}
              <InformationItem icon={<UserOutlined />} value={appointment.location.contactPerson} />
              <InformationItem icon={<MailOutlined />} value={appointment.location.primaryEmail} />
              {appointment.location.phoneNumber && <InformationItem icon={<PhoneOutlined />} value={appointment.location.phoneNumber} />}
              {appointment.location.secondaryEmail && <InformationItem icon={<MailFilled />} value={appointment.location.secondaryEmail} />}
              {appointment.location.mobilePhoneNumber && (
                <InformationItem icon={<PhoneFilled />} value={appointment.location.mobilePhoneNumber} />
              )}
              {appointment.requester.id !== "1" && (
                <InformationItem icon={<CustomerServiceOutlined />} value={`Aangevraagd door: ${appointment.requester.username}`} />
              )}
            </Col>
          </Row>
          {appointment.relation.responsibleEmployee !== null && (
            <Alert
              type="info"
              message={`${appointment.relation.responsibleEmployee.username} is de verantwoordelijke salesmedewerker`}
              style={{ backgroundColor: "transparent", margin: "2px 0 8px 0" }}
              showIcon
            />
          )}
          {appointment.confirmedByCustomer !== null && (
            <Alert
              type="info"
              message={`Afspraak is bevestigd door ${appointment.confirmedByCustomer ? "klant" : "medewerker"}`}
              style={{ backgroundColor: "transparent", margin: "2px 0 8px 0" }}
              showIcon
            />
          )}
          {(appointment.relation.planningComment || appointment.relation.onSiteComment) && (
            <Row justify="space-between">
              {appointment.relation.planningComment && (
                <Col md={11}>
                  <OptionalInformation label="Planning notities (relatie)" value={appointment.relation.planningComment} />
                </Col>
              )}
              {appointment.relation.onSiteComment && (
                <Col md={11}>
                  <OptionalInformation label="Buitendienst notities (relatie)" value={appointment.relation.onSiteComment} />
                </Col>
              )}
            </Row>
          )}
          <Form.Item valuePropName="initialValue" label="Planning notities (locatie)" name="locationPlanningComment">
            <MarkdownTextInput />
          </Form.Item>
          <Form.Item label="Buitendienst notities (locatie)" name="locationOnSiteComment">
            <Input.TextArea rows={2} />
          </Form.Item>
          <ProductsTables appointment={appointment} />
          <Divider>Afspraak</Divider>
          <Row justify="space-between" gutter={24}>
            <Col span={12}>
              <Form.Item label="Afspraak-type">
                <AppointmentTypePicker
                  disabled
                  preloadWithAppointmentType={{
                    ...appointment.appointmentType,
                    parentCategoryId: appointment.appointmentType.category.id,
                  }}
                  value={appointment.appointmentType.id}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Monteur"
                name="employeeId"
                rules={[{ required: appointment.status === "STATUS_CONFIRMED" || appointment.status === "STATUS_SCHEDULED" }]}
              >
                <EmployeePicker groupId="GROUP_SERVICE_EMPLOYEES" />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="space-between" gutter={24}>
            <Col span={12}>
              <Form.Item
                label="Datum"
                name="date"
                rules={[{ required: appointment.status === "STATUS_CONFIRMED" || appointment.status === "STATUS_SCHEDULED" }]}
              >
                <DatePicker format="LL" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Tijd" extra={`Schatting: ${formatDuration(appointment.durationEstimate)}`}>
                <Form.Item
                  name="startTime"
                  style={{ display: "inline-block", width: "calc(50% - 16px)" }}
                  rules={[{ required: appointment.status === "STATUS_CONFIRMED" || appointment.status === "STATUS_SCHEDULED" }]}
                >
                  <TimePicker hideDisabledOptions format="HH:mm" minuteStep={5} placeholder="" />
                </Form.Item>
                <span style={{ display: "inline-block", width: "32px", lineHeight: "32px", textAlign: "center" }}>-</span>
                <Form.Item
                  className="appointment-end-time"
                  name="endTime"
                  style={{ display: "inline-block", width: "calc(50% - 16px)" }}
                  rules={[{ required: appointment.status === "STATUS_CONFIRMED" || appointment.status === "STATUS_SCHEDULED" }]}
                >
                  <TimePicker hideDisabledOptions format="HH:mm" minuteStep={5} placeholder="" />
                </Form.Item>
              </Form.Item>
            </Col>
          </Row>
          <Space direction="vertical" size="small">
            <Form.Item
              valuePropName="initialValue"
              label="Planning notities (afspraak)"
              name="appointmentPlannerComment"
              style={{ marginBottom: 0 }}
            >
              <MarkdownTextInput />
            </Form.Item>

            <AppointmentRemindMeSidebar appointmentId={appointmentId} />
          </Space>
          <Form.Item label="Buitendienst notities (afspraak)" name="appointmentOnSiteComment">
            <Input.TextArea rows={2} />
          </Form.Item>
        </Form>
      )}
    </Card>
  ) : null;
}

const AppointmentQuery = gql`
  query ($appointmentId: ID!) {
    appointment(id: $appointmentId) {
      id
      appointmentType {
        id
        name
        hasEmailTemplate
        category {
          id
          name
        }
      }
      relation {
        id
        name
        place
        afasCode
        responsibleEmployee {
          id
          username
        }
        dealer {
          id
          afasCode
          name
        }
        planningComment
        onSiteComment
      }
      location {
        id
        name
        contactPerson
        primaryEmail
        secondaryEmail
        phoneNumber
        mobilePhoneNumber
        planningComment
        onSiteComment
        address {
          street
          city
          postalCode
          country
        }
      }
      employee {
        id
        username
      }
      requester {
        id
        username
      }
      status
      confirmedByCustomer
      prospectiveDate
      inviteSentOn
      startTime
      endTime
      durationEstimate
      onSiteComment
      plannerComment
      consumableGoods {
        id
        productType {
          id
          description
        }
        amount
      }
    }
  }
`;

const ScheduleAppointmentMutation = gql`
  mutation ($id: ID!, $startTime: DateTime!, $endTime: DateTime!, $employeeId: ID!, $onSiteComment: String, $plannerComment: String) {
    scheduleAppointment(
      input: {
        id: $id
        startTime: $startTime
        endTime: $endTime
        employeeId: $employeeId
        onSiteComment: $onSiteComment
        plannerComment: $plannerComment
      }
    ) {
      appointment {
        id
        status
        startTime
        endTime
        employee {
          id
          username
        }
        onSiteComment
        plannerComment
        confirmedByCustomer
      }
    }
  }
`;

const UpdateAppointmentMutation = gql`
  mutation ($id: ID!, $startTime: DateTime, $endTime: DateTime, $onSiteComment: String, $plannerComment: String, $employeeId: ID) {
    updateAppointment(
      input: {
        id: $id
        startTime: $startTime
        endTime: $endTime
        onSiteComment: $onSiteComment
        plannerComment: $plannerComment
        employeeId: $employeeId
      }
    ) {
      appointment {
        id
        startTime
        endTime
        onSiteComment
        plannerComment
        confirmedByCustomer
        employee {
          id
          username
        }
      }
    }
  }
`;

const UnscheduleAppointmentMutation = gql`
  mutation ($id: ID!) {
    unscheduleAppointment(input: { id: $id }) {
      appointment {
        id
        inviteSentOn
        startTime
        endTime
        confirmedByCustomer
        employee {
          id
          username
        }
        status
      }
    }
  }
`;

const UpdateLocationMutation = gql`
  mutation ($relationId: ID!, $locationId: ID!, $planningComment: String, $onSiteComment: String) {
    updateLocation(
      input: { relationId: $relationId, locationId: $locationId, planningComment: $planningComment, onSiteComment: $onSiteComment }
    ) {
      location {
        id
        planningComment
        onSiteComment
      }
    }
  }
`;

const PriorAppointmentsQuery = gql`
  query ($locationId: ID!, $afterDate: DateTime!, $beforeDate: DateTime!, $status: [AppointmentStatus!]) {
    appointments(locationId: $locationId, status: $status, afterDate: $afterDate, beforeDate: $beforeDate) {
      id
    }
  }
`;

export default AppointmentPane;
